<template>
  <div>
    <div class="title">
      <h3 class="h2-super_title txt-bold">
          著作權聲明 <img src="@/assets/img/icon/icon_exclamation.svg" alt="">
      </h3>
    </div>
    <div class="textbox">
      <!-- <div class="top">
          <h6 class="txt-bold">Telecom Square 無線網路分享器租賃服務 (按天計費方案）使用基本規章</h6>
      </div> -->
      <div class="info">
        <!-- eslint-disable -->
          <p>
              特樂通官網（以下簡稱本網站）上刊載的所有內容，包括但不限於文字報導、照片、影像、插圖、錄音片、影音片等素材，均受到中華民國著作權法及國際著作權相關法規之保障。<br><br>
              本網站內容之著作權為特樂通股份有限公司，或其他授權特樂通股份有限公司使用的內容提供者所有。<br><br>
                除經特樂通股份有限公司之書面同意者外，使用者於使用、下載或拷貝本網站提供之內容及其相關素材時，僅得於使用者個人、非商業用途之方式使用，且使用者於使用時應遵守著作權法之相關規定。<br><br>
              使用者不可變更、發行、播送、轉賣、重製、改作、散布、表演、展示或利用本網站的局部或全部的內容及相關素材，亦不得為商業用途或謀取利益。<br><br>
              如欲轉載本網站內容或請求其他方式之使用，請洽詢特樂通客服。
          </p>
      </div>
      <p class="txt-greenlake txt-right">2013 年 08 月 29 日 制定、施行</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Copyright',
};
</script>
