export default {
  /**
   * @name 登入
   */
  baseUrl: 'articles',
  index: {
    url: '/getArticles',
    token: false,
  },
  getArticlesByTag: {
    url: '/getArticlesByTag',
    token: false,
  },
  getTags: {
    url: '/getTags',
    token: false,
  },
  info: {
    url: '/getArticleContent',
    token: false,
  },
  getRelationship: {
    url: '/getRelationship',
    token: false,
  },
};
