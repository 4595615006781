<template>
  <div
    class="wh-select"
    ref="whselectWrap"
  >
    <span
      class="
        select2
        select2-container
        select2-container--default
        select2-container--focus
        select2-container--below
        select2-container--open
      "
      dir="ltr"
      style="width: 298.922px"
      ref="whselect"
    >
      <span class="selection">
        <span
          class="select2-selection select2-selection--multiple"
          role="combobox"
          aria-haspopup="true"
          aria-expanded="true"
          tabindex="-1"
          aria-disabled="false"
          aria-owns="select2-index_destination-results"
          aria-activedescendant="select2-index_destination-result-gh7y-113"
          @click="methodToggleShow"
        >
          <ul class="select2-selection__rendered">
            <li
              v-for="(item, idx) in selected"
              :key="`wh-select-selected-item-${idx}`"
              class="select2-selection__choice"
              :title="item.text"
            >
              <span
                @click="methodSelect(item, $event)"
                class="select2-selection__choice__remove"
                role="presentation"
                >×</span>
              {{item.text}}
            </li>
            <li class="select2-search select2-search--inline">
              <input
                class="select2-search__field"
                type="search"
                tabindex="0"
                autocomplete="off"
                autocorrect="off"
                autocapitalize="none"
                spellcheck="false"
                role="searchbox"
                aria-autocomplete="list"
                :placeholder="hidePlaceholder ? '' : placeholder"
                @keydown="methodKeypressHandle"
                style="width: 279.922px"
                v-model="filterText"
                aria-controls="select2-index_destination-results"
                aria-activedescendant="select2-index_destination-result-gh7y-113"
              />
            </li>
          </ul>
        </span>
      </span>
    </span>
    <!-- dropdown -->
      <span
        v-if="show"
        class="select2-container select2-container--default select2-container--open dropdown-wrap"
        :style="dropdownContentStyle"
        >
        <span
          class="select2-dropdown select2-dropdown--below dropdown-content-wrap"
          dir="ltr"
          >
          <span class="select2-results">
            <ul
              class="select2-results__options"
              role="listbox"
              aria-multiselectable="true"
              aria-hidden="false"
            >
              <template
                v-if="overLimit"
              >
                <li
                  role="alert"
                  aria-live="assertive"
                  class="select2-results__option select2-results__message"
                >You can only select {{limit}} items</li>
              </template>
              <template
                v-else
              >
                <li
                  v-for="(item, idx) in optionsShow"
                  :key="`wh-select-item-${idx}`"
                  class="select2-results__option dropdown-item"
                  role="option"
                  :aria-disabled="`${item.disabled}`"
                  :aria-selected="`${methodIsSelected(item)}`"
                  @click="methodSelect(item, $event)"
                >
                  {{item.text}}
                </li>
              </template>
            </ul>
          </span>
        </span>
      </span>

  </div>

</template>

<script>
import { isEqual, isArray } from '@/lib/lodash';

export default {
  name: 'WhSelect',
  props: {
    options: {
      type: Array,
      default: () => [],
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    limit: {
      type: [Number, String],
    },
    value: {
      type: [Array, String],
      default: () => [],
    },
  },
  data() {
    return {
      count: 0,
      show: false,
      selected: [],
      filterText: '',
      dropdownContentStyle: { },
    };
  },
  computed: {
    parentVal() {
      if (this.value && this.value.length && this.options && this.options.length) {
        const selected = [];
        this.value.forEach((id) => {
          if (!selected.includes(id)) {
            selected.push({
              ...this.options.find((item) => item.id === id),
            });
          }
        });
        return selected;
      } return [];
    },
    hidePlaceholder() {
      return this.selected?.length > 0;
    },
    optionsShow() {
      if (!this.filterText) return this.options;
      const regexp = new RegExp(this.filterText, 'g');
      const list = [];
      return this.options?.filter(({ text = '', disabled = false, id = '' } = {}) => {
        const valid = text.match(regexp);
        const isInclude = list?.find((v) => v === id);
        if (!isInclude) {
          list.push(id);
          return valid && !disabled;
        }
        return false;
      });
    },
    overLimit() {
      return this.limit && this.limit > 0 ? this.selected.length >= this.limit : false;
    },
  },
  watch: {
    parentVal(v) {
      this.selected = v;
    },
  },
  mounted() {
    window.addEventListener('scroll', () => { this.show = false; });
    window.addEventListener('click', this.methodDetectToClose);
    window.addEventListener('resize', this.methodUpdateDropdownContent);
    let tmp = setInterval(() => {
      this.methodUpdateDropdownContent();
    }, 1000);
    setTimeout(() => {
      clearInterval(tmp);
      tmp = null;
      this.methodUpdateDropdownContent();
    }, 2000);
  },
  methods: {
    isEqual,
    methodToggleShow() {
      this.methodUpdateDropdownContent();
      this.show = !this.show;
    },
    methodIsSelected(i) {
      const res = this.selected?.find((v) => isEqual(v, i));
      return !!res;
    },
    methodSelect(i, e) {
      if (e?.preventDefault)e.preventDefault();
      if (e?.stopPropagation)e.stopPropagation();
      if (i?.disabled) return;
      const haveIndex = this.selected?.findIndex((v) => isEqual(v, i));
      if (haveIndex !== -1) {
        this.selected.splice(haveIndex, 1);
        this.methodToggleShow();
        this.methodUpdateValue();
      } else {
        if (this.limit && this.limit > 0) {
          if (this.selected?.length >= this.limit) return;
        }
        this.selected.push(i);
        this.methodToggleShow();
        this.methodUpdateValue();
      }
    },
    methodUpdateValue() {
      const result = this.selected?.map(({ id } = {}) => id);
      this.filterText = '';
      this.$emit('input', result);
    },
    methodKeypressHandle(e) {
      const key = e?.keyCode || e?.charCode;
      if (key === 8 || key === 46) {
        if (this.selected?.length > 0) this.methodSelect(this.selected[this.selected.length - 1]);
      }
    },
    methodUpdateDropdownContent() {
      const elm = this.$refs.whselect?.getBoundingClientRect() ?? {};

      // if (elm) {
      this.dropdownContentStyle = {
        position: 'fixed',
        inset: 'auto',
        top: `${elm.top + elm.height}px`,
        left: `${elm.left}px`,
        right: 'auto',
        bottom: 'auto',
      };
      // } else {
      //   this.dropdownContentStyle = {};
      // }
    },
    methodDetectToClose(v) {
      const target = v && isArray(v?.path)
        ? v?.path?.find((e) => e === this.$refs?.whselectWrap) ?? null : null;
      if (this.show && this.count > 0 && !target) {
        this.show = false;
        this.count = 0;
      }
      if (this.show) this.count += 1;
    },
  },
  destroyed() {
    window.removeEventListener('resize', this.methodUpdateDropdownContent);
    window.removeEventListener('click', this.methodDetectToClose);
    this.show = false;
  },
};
</script>
<style lang="scss" scoped>
.wh-select {
  position: relative;
}
.dropdown-wrap {
  // position: absolute;
  // top: 100%;
  // left: 0;
  // transform: translateX(-50%);
  z-index: 10;

}
.select2-results__option {
  &.dropdown-item[aria-disabled=false] {
    &:hover {
      background-color: #5897fb;
      color: white;
    }
  }
  &[aria-disabled=true] {
    cursor: default;
  }
}
.dropdown-content-wrap {
  width: calc(100vw - 100px);
}

@media (min-width: 632px) {
  .dropdown-content-wrap {
    width: 261.984px
  }
}
</style>
