<template>
  <section id="kv">
    <swiper
      :options="swiperOption"
      v-if="computedKvData.length"
      class="kv_box swiper-container">
      <swiper-slide
        v-for="(item, key) in computedKvData"
        :key="key">
        <figure
          :style="{backgroundImage: `url(${item.bg})`}"
          class="kv_item">
            <div class="cover"></div>
            <div class="text txt-white">
              <div>
                <h2 class="h1 txt-bold">
                  最值得企業客戶信賴<br>
                  海外上網品牌首選
                </h2>
                <h3 class="txt-bold">
                  跨國據點，全台首間行動網路租借服務公司
                </h3>
              </div>
              <img src="@/assets/img/company/banner_bago.png" class="m768-off" alt="">
            </div>
            <img src="@/assets/img/company/banner_bago.png" class="m768-on" alt="">
        </figure>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </section>
</template>

<script>
import defaultBanner from '@/assets/img/company/banner.jpg';
import defaultMobileBanner from '@/assets/img/company/mobile-company-banner.jpg';

export default {
  name: 'CompanyKv',
  data() {
    return {
      windowWidth: window.innerWidth,
      kvData: [
        {
          bg: defaultBanner,
          bgMobile: defaultMobileBanner,
        },
      ],
      swiperOption: {
        loop: false,
        slidesPerView: 1,
        spaceBetween: 0,
        allowTouchMove: true,
        autoplay: true,
        delay: 5000,
        speed: 2000,
        pagination: {
          el: '#kv .swiper-pagination',
          clickable: true,
        },
      },
    };
  },
  methods: {
    getBanners() {
      this.kvData = [];
    },
  },
  computed: {
    computedKvData() {
      const temp = [];

      for (let i = 0; i < this.kvData.length; i += 1) {
        if (this.windowWidth > 768) {
          temp.push({
            bg: this.kvData[i].bg,
          });
        } else {
          temp.push({
            bg: this.kvData[i].bgMobile,
          });
        }
      }

      return temp;
    },
  },
  mounted() {
    // this.getBanners();
  },
};
</script>
