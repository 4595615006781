<template>
  <div id="faq">
    <Header/>
    <div id="faq_top">
        <div class="inner w1300 txt-white">
          <div class="title txt-center sr_right">
            <p class="txt-white txt-style-i">
              FAQ
              <img src="@/assets/img/icon/icon_exclamation-w.svg" alt="" style="width: .65em;">
            </p>
            <h2 class="h2-super_title txt-bold">
              常見問題 <img src="@/assets/img/icon/icon_exclamation-w.svg" alt="">
            </h2>
          </div>
        </div>
    </div>

    <div id="step">
      <div class=" w1400 breadcrumbs">
        <span>
          <router-link to="/">首頁</router-link>
        </span> > 常見問題FAQ
      </div>
      <div class="inner w1400">
        <div class="bg">
            <div
              class="bg2"
              :style="`background-image: url(${require('@/assets/img/feedback/deco.png')})`">
            </div>
        </div>

        <ArticleTag
          :currentIndex="currentIndex"
          :categoryArray="categoryArray"/>

        <div class="itembox">
          <div class="item" v-for="(item, i) in itemsArray" :key="`item_${i}`">
            <div class="top" @click="item.show = !item.show">
              <div class="top_head">
                <img src="@/assets/img/icon/-.svg" alt="" class="del" v-if="item.show">
                <img src="@/assets/img/icon/+.svg" alt="" class="add" v-else>
                <h6>{{item.title}}</h6>
              </div>
              <img src="@/assets/img/icon/icon_wave_b.svg" class="wave" alt="">
            </div>
            <div class="content" :class="{ active: item.show }" v-html="item.content" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import ArticleTag from '@/components/article/ArticleTag.vue';

import requestApi from '@/lib/http/index';

import '@/assets/scss/service.scss';

export default {
  name: 'Faq',
  props: ['category'],
  components: {
    Header,
    ArticleTag,
  },
  data() {
    return {
      categoryArray: [
        {
          name: '商品租借與購買',
          link: '/Faq/1',
        },
        {
          name: '分類2',
          link: '/Faq/2',
        },
      ],
      currentIndex: 0,
      itemsArray: [
        {
          title: '標題',
          content: '內容',
        },
      ],
    };
  },
  methods: {
    // eslint-disable-next-line
    async getData(type_id) {
      const result = await requestApi('faq.getFaqs', { type_id });

      if (result.status) {
        const arr = [];
        result.data.forEach((element) => {
          const { title, content } = element;
          arr.push({
            title,
            content,
            show: false,
          });
        });
        this.itemsArray = arr;
      } else {
        this.itemsArray = [];
        // this.$customSWAL({
        //   icon: 'error',
        //   title: '錯誤',
        //   text: '無法取得資料',
        //   confirmButtonText: '確定',
        // });
      }
    },
    async getCategory() {
      const result = await requestApi('faq.getTypes');

      if (result.status) {
        const arr = [];
        result.data.forEach((element) => {
          arr.push({
            id: element.id,
            name: element.name,
            link: `/Faq/${element.id}`,
          });
        });
        this.categoryArray = arr;
      } else {
        this.categoryArray = [];
        this.$customSWAL({
          icon: 'error',
          title: '錯誤',
          text: '無法取得分類',
          confirmButtonText: '確定',
        });
      }
    },
    async getCategoryAndData() {
      const result = await requestApi('faq.getTypes');

      if (result.status) {
        const arr = [];
        result.data.forEach((element) => {
          arr.push({
            id: element.id,
            name: element.name,
            link: `/Faq/${element.id}`,
          });
        });
        this.categoryArray = arr;
        this.currentIndex = 0;
        this.getData(this.categoryArray[0].id);
      } else {
        this.categoryArray = [];
        this.$customSWAL({
          icon: 'error',
          title: '錯誤',
          text: '無法取得分類',
          confirmButtonText: '確定',
        });
      }
    },
    passIndex(/* index */) {
    },
    changeCategoryIndex(category) {
      this.categoryArray.forEach((item, i) => {
        if (item.id === category) {
          this.currentIndex = i;
        }
      });
    },
  },
  watch: {
    $route(to, from) {
      if (to !== from) {
        this.getData(to.params.category);
        this.changeCategoryIndex(to.params.category);
      }
    },
  },
  mounted() {
    if (this.$route.params.category !== '1') {
      const getCategory = Promise.resolve(this.getCategory());
      getCategory.then(() => {
        this.getData(this.$route.params.category);
        this.changeCategoryIndex(this.$route.params.category);
      });
    } else {
      this.getCategoryAndData();
    }
  },
};
</script>
