import keyList from './list';

/**
 * 取得 localStorage
 * @param {String} storageName - 要取loacalstorage的keyName
 */
export const getStorage = (keyName, getTime = false) => {
  const key = keyList[keyName] || '';
  if (!key) return new Error('key is required');
  const expiredTime = JSON.parse(
    window.localStorage.getItem('expiredTime') || '{}',
  );
  const expired = expiredTime[key];
  // 若時間已過期 就刪除storage
  if (expired && new Date().getTime() > expired) {
    window.localStorage.removeItem(key);
    delete expiredTime[key];
    window.localStorage.setItem('expiredTime', JSON.stringify(expiredTime));
  }
  const value = JSON.parse(window.localStorage.getItem(key));
  if (getTime) return [value, expired];
  return value;
};

export const checkStorage = (keyName) => {
  const key = keyList[keyName] || '';
  if (!key) return new Error('key is required');
  return window.localStorage.getItem(key) !== null;
};

/**
 * 置入有時間限制的storage
 * @param {String} key    - storage的名稱
 * @param {String} value  - storage的值
 * @param {String} expire - 到期時間
 */
export const setStorage = (keyName = '', value = '', expiredIn = null) => {
  const key = keyList[keyName] || '';
  if (!key) return new Error('key is required');
  if (!value && value !== false && value !== 0) {
    localStorage.removeItem(key);
    return null;
  }
  localStorage.setItem(key, JSON.stringify(value));
  if (expiredIn) {
    const expiredTime = JSON.parse(
      window.localStorage.getItem('expiredTime') || '{}',
    );
    localStorage.setItem(
      'expiredTime',
      JSON.stringify({ ...expiredTime, [key]: expiredIn }),
    );
  }
  return null;
};

/**
 * 刪除storage
 * @param {Array} exclude - 排除
 */
export const clearStorage = (exclude = []) => {
  Object.keys(keyList).forEach((keyName) => {
    if (!exclude.includes(keyName)) {
      window.localStorage.removeItem(keyList[keyName]);
    }
  });
};
