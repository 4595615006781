export default {
  /**
   * @name 登入
   */
  baseUrl: 'company_consults',
  store: {
    url: '/store',
    method: 'post',
    token: false,
  },
};
