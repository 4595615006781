<template>
  <section id="convenient" ref="convenient">
    <div
      class="bg backimg"
      :style="`background-image: url(${require('@/assets/img/index/img_step.jpg')});`">
    </div>
    <div class="bg oblique"></div>
    <div
      class="bg deco"
      :style="`background-image: url(${require('@/assets/img/icon/deco_exclamation_big.svg')});`">
    </div>

    <div class="inner w1300">
      <div class="top">
        <h2
          v-scroll-reveal="$sr_rightSetting"
          class="h2-super_title txt-bold">
            超簡單步驟，租借、購買都方便
            <img src="@/assets/img/icon/icon_exclamation.svg" alt="">
        </h2>
      </div>
      <div class="down">
        <div class="btnset">
          <span
            v-for="(item, key) in itemData"
            :key="key"
            :class="{active: nowChooseItem == key}"
            class="btn btn-grey"
            style="padding: 6px 30px;"
            @click="stepRun(key)">
              <p>{{item.title}}</p>
          </span>
        </div>
        <div class="showbox active">
          <div class="stepbar">
            <template
              v-for="(item, key) in itemData[nowChooseItem].step">
                <div
                  :key="`item${key}`"
                  :class="{
                    active: nowStep == key,
                    past: nowStep > key,
                  }"
                  class="step"
                  @click="clickChangeStep(key)">
                    <h4 class="step_name txt-bold">{{item.stepName}}</h4>
                    <div class="number">
                      <p>{{key+1}}</p>
                    </div>
                </div>
                <div
                  v-if="key < (itemData[nowChooseItem].step.length - 1)"
                  :key="`gap${key}`"
                  class="stepgap">
                    <div
                      :class="{active: nowStepGap >= key}"
                      :style="{
                        animationDuration:
                          nowStepAnimationRunning
                          ? stepKeepTime/1000+'s'
                          : '0s'
                      }"
                      class="stepgap-progress">
                    </div>
                </div>
            </template>
          </div>
          <div class="stepinfo">
            <div
              v-for="(item, key) in itemData[nowChooseItem].step"
              :key="key"
              :class="{active: nowStep == key}"
              class="stepinfo_item">
                <h3>{{item.stepTitle}}</h3>
                <h5 class="txt-blue">{{item.stepInfo}}</h5>
            </div>
          </div>
          <div class="btnbox txt-center">
            <router-link :to="faqLink" class="btn btn-blue_blue_line">
              <img src="@/assets/img/icon/icon_next-gl.svg" alt="">
              <p>查看更多</p>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import requestApi from '@/lib/http/index';

export default {
  name: 'IndexConvenient',
  data() {
    return {
      nowChooseItem: 0,
      nowStep: 0,
      nowStepGap: -1,
      nowStepAnimationRunning: false,
      animationStartOnce: false,
      countStep: null,
      stepKeepTime: 2500,
      faqLink: '/Faq/1',
      categoryArray: [],
      itemData: [
        {
          title: '租借 WiFi 分享器',
          step: [
            {
              stepName: '預約',
              stepTitle: '24小時皆可預約，收費透明公開',
              stepInfo: '便利服務、急件預約也OK(出國前一天中午12點前都來的及)',
            },
            {
              stepName: '付款',
              stepTitle: '支援線上刷卡及現金付款',
              stepInfo: '可使用VISA、MASTER、JCB信用卡、LINE Pay 行動支付',
            },
            {
              stepName: '取機',
              stepTitle: '提供多種取機方式！',
              stepInfo: '宅配到府、機場櫃台/門市、便利商店皆可取件',
            },
            {
              stepName: '出國',
              stepTitle: '365天！多管道專業客服支援服務',
              stepInfo: '客戶高評價肯定 ! 提供專業客戶支援服務',
            },
            {
              stepName: '還機',
              stepTitle: '機場還機，便利、簡單又省運費',
              stepInfo: '24小時機場還機箱',
            },
          ],
          link: {
            name: 'Product',
            params: { category: 1 },
            query: { productCategory: 1 },
          },
        },
        {
          title: '購買 SIM 卡',
          step: [
            {
              stepName: '購買',
              stepTitle: '經濟實惠、方案種類選擇多',
              stepInfo: '隨插即用、免歸還',
            },
            {
              stepName: '付款',
              stepTitle: '支援線上刷卡及臨櫃現金付款',
              stepInfo: '可使用VISA、MASTER、JCB信用卡、LINE Pay 行動支付',
            },
            {
              stepName: '取卡',
              stepTitle: '提供多種取卡方式！',
              stepInfo: '宅配到府、機場櫃台/門市、便利商店皆可取件',
            },
            {
              stepName: '出國',
              stepTitle: '365天！多管道專業客服支援服務',
              stepInfo: '客戶高評價肯定 ! 提供專業客戶支援服務',
            },
          ],
          link: {
            name: 'Product',
            params: { category: 1 },
            query: { productCategory: 2 },
          },
        },
        {
          title: '購買 eSIM',
          step: [
            {
              stepName: '購買',
              stepTitle: '無卡上網商務新選擇',
              stepInfo: '免換卡，原門號可通話，重要電話不漏接',
            },
            {
              stepName: '付款',
              stepTitle: '支援線上刷卡及臨櫃現金付款',
              stepInfo: '可使用VISA、MASTER、JCB信用卡、LINE Pay 行動支付',
            },
            {
              stepName: '取卡',
              stepTitle: '24小時Email收件超方便',
              stepInfo: '免等待物流配送、減去包裝耗材又環保',
            },
            {
              stepName: '出國',
              stepTitle: '365天！多管道專業客服支援服務',
              stepInfo: '客戶高評價肯定 ! 提供專業客戶支援服務',
            },
          ],
          link: {
            name: 'Product',
            params: { category: 1 },
            query: { productCategory: 3 },
          },
        },
      ],
    };
  },
  methods: {
    stepRun(num) {
      this.changeLink(num);
      clearInterval(this.countStep);
      this.nowStep = 0;
      this.nowStepGap = -1;
      this.nowChooseItem = num;
      this.nowStepAnimationRunning = true;
      setTimeout(() => {
        this.nowStepGap += 1;
      }, 100);
      this.countStep = setInterval(() => {
        if (this.nowStep < this.itemData[this.nowChooseItem].step.length - 1) {
          this.nowStep += 1;
          this.nowStepGap += 1;
        } else {
          clearInterval(this.countStep);
          this.nowStepAnimationRunning = false;
        }
      }, this.stepKeepTime + 100);
    },
    clickChangeStep(num) {
      clearInterval(this.countStep);
      this.nowStep = num;
      this.nowStepGap = num - 1;
      this.nowStepAnimationRunning = false;
    },
    startFirstAnimation() {
      const elOffestTop = this.$refs.convenient.offsetTop;
      if (window.scrollY >= elOffestTop - window.innerHeight / 2 && !this.animationStartOnce) {
        this.animationStartOnce = true;
        this.stepRun(0);
        window.removeEventListener(
          'scroll',
          this.startFirstAnimation,
          true,
        );
      }
    },
    changeLink(index) {
      // 0: wifi 1: sim 2: esim
      if (this.categoryArray.length > 0) {
        this.categoryArray.forEach((item) => {
          if (item.name === 'WiFi分享器使用相關' && index === 0) {
            this.faqLink = { name: 'Product', params: { category: '1' }, query: { productCategory: 1 } };
          } else if (item.name === 'SIM卡使用相關' && index === 1) {
            this.faqLink = { name: 'Product', params: { category: '1' }, query: { productCategory: 2 } };
          } else if (item.name === 'eSIM使用相關' && index === 2) {
            this.faqLink = { name: 'Product', params: { category: '1' }, query: { productCategory: 3 } };
          }
        });
      }
    },
    async getCategory() {
      const result = await requestApi('faq.getTypes');

      if (result.status) {
        const arr = [];
        result.data.forEach((element) => {
          arr.push({
            id: element.id,
            name: element.name,
            link: `/Faq/${element.id}`,
          });
        });
        this.categoryArray = arr;
      }
    },
  },
  mounted() {
    this.getCategory();
    window.addEventListener(
      'scroll',
      this.startFirstAnimation,
      true,
    );
  },
  destroyed() {
    window.removeEventListener(
      'scroll',
      this.startFirstAnimation,
      true,
    );
  },
};
</script>
