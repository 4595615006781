<template>
  <div id="app">
    <router-view/>
    <Footer
      v-if="
        $route.name !== 'FamilyMartData'
        && $route.name !== 'LinepayConfirm'
        && $route.name !== 'LinepayCancel'
        && $route.name !== 'CathayResult'
      "/>
    <Lightboxs
      v-if="
        $route.name !== 'FamilyMartData'
        && $route.name !== 'LinepayConfirm'
        && $route.name !== 'LinepayCancel'
        && $route.name !== 'CathayResult'
      "/>
  </div>
</template>

<script>
// import { checkStorage } from '@/utils/timerStorage';
import { mapActions, mapState, mapMutations } from 'vuex';
import Footer from '@/components/Footer.vue';
import Lightboxs from '@/components/Lightboxs.vue';
import requestApi from '@/lib/http';
import {
  checkStorage,
  setStorage,
} from '@/utils/timerStorage';

import '@/assets/scss/main.scss';
import '@/assets/scss/public.scss';

export default {
  name: 'App',
  components: {
    Footer,
    Lightboxs,
  },
  computed: {
    ...mapState([
      'isLogin',
    ]),
  },
  methods: {
    ...mapActions({
      methodInit: 'init',
      actionLogout: 'logout',
    }),
    ...mapMutations([
      'setMemberInfo',
    ]),
    logout() {
      this.actionLogout();
      this.ifToken = checkStorage('token');
      this.$router.go(0);
      // this.$router.push({ name: 'Index' });
    },
    checkMemberInfo(data) {
      let check = true;
      if (data) {
        if (Object.keys(data).length > 0) {
          Object.keys(data).forEach((key) => {
            if (
              key === 'account'
              || key === 'name'
              || key === 'sex'
              || key === 'birthday'
              || key === 'email'
              || key === 'country_code'
              || key === 'mobile'
              || key === 'county'
              || key === 'district'
              || key === 'address'
            ) {
              if (data[key] === '' || data[key] === null) {
                check = false;
              }
            }
          });
        }
        this.setMemberInfo(check);
        setStorage('memberInfo', check);
      }
      return check;
    },
    async checkTokenAlive() {
      const token = localStorage.getItem('ust');
      if (token) {
        const { status, message, data } = await requestApi('member.profile');
        this.checkMemberInfo(data);
        if (!token && this.isLogin) {
          this.logout();
        }
        if (!status && message === 'Api Token 失效') {
          this.logout();
        }
      }
    },
  },
  created() {
    console.log('version: 4.3');

    this.methodInit();
    this.checkTokenAlive();
  },
  updated() {
    this.checkTokenAlive();
  },
  watch: {
    $route: {
      handler(newVal, oldVal) {
        if (newVal.name !== oldVal.name) {
          const code = window.localStorage.getItem('marketCode');

          if (code && !newVal.query?.code) {
            this.$router.push({
              query: {
                code,
              },
            });
          }
        }
      },
    },
  },
};
</script>
