<template>
  <section id="easy">
    <div class="top main_padding">
      <div class="inner w1300">
        <form class="search_area">
          <input type="hidden" name="category_id" value="all">
          <div class="left">
            <h4 class="txt-bold">
              國際Wi-Fi 通訊服務品牌&nbsp;
              <img src="@/assets/img/icon/icon_exclamation.svg" alt="" style="width: .65em;">
            </h4>
            <div class="bageset">
              <div class="bage">
                <figure
                  :style="`background-image: url(${require('@/assets/img/index/bage_1.png')});`">
                </figure>
              </div>
              <div class="bage">
                <figure
                  :style="`background-image: url(${require('@/assets/img/index/bage_2.png')});`">
                </figure>
              </div>
              <div class="bage">
                <figure
                  :style="`background-image: url(${require('@/assets/img/index/bage_3.png')});`">
                </figure>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="filldata">
              <div class="filldata_in col66">
                <div>
                  <WhSelect
                    v-model="searchData.targetCountry"
                    :options="countries"
                    placeholder="請輸入目的地"
                    limit="2"
                  />
                </div>
              </div>
              <div class="filldata_in col33">
                <select
                  name="product_flow_type_id"
                  id="product_flow_type_id"
                  v-model="searchData.flow">
                    <option
                      v-for="(flow, i) in flowTypes" :key="`flow_${i}`" :value="flow.id">
                      {{flow.text}}
                    </option>
                </select>
              </div>
              <div class="filldata_in col33">
                <input
                  v-model="searchData.unspecifiedTime"
                  type="checkbox"
                  name="unspecifiedTime"
                  id="no_time_limit"
                  value="1">
                <label for="no_time_limit"><span></span> 不指定日期</label>
              </div>
              <div
                :class="{active: !searchData.unspecifiedTime}"
                id="index_start_time"
                class="filldata_in col66">
                  <date-picker
                      v-model="searchData.dateRange"
                      class="datePicker"
                      type="date"
                      range
                      :disabled="searchData.unspecifiedTime"
                      :disabledDate="date => date <= new Date().setDate(new Date().getDate() - 1)"
                      placeholder="出國日 － 回國日">
                  </date-picker>
              </div>
            </div>
            <button
              @click="search"
              type="submit" class="btn btn-orange_white_line">
              <img src="@/assets/img/icon/icon_next-o.svg" alt="">
              <p>
                立即搜尋
              </p>
            </button>
          </div>
        </form>
        <div style="overflow: hidden;">
          <h2
            v-scroll-reveal="$sr_rightSetting"
            class="h2-super_title txt-bold sr_right">
              出國上網超 <span class="txt-highbold txt-style-i">EASY</span><br>
              <span class="txt-highbold txt-style-i">3</span> 種上網工具特性一次搞清楚
              <img src="@/assets/img/icon/icon_exclamation.svg" alt="">
          </h2>
        </div>
      </div>
      <figure
        class="bg"
        :style="`background-image: url(${require('@/assets/img/index/deco_wave.svg')});`">
      </figure>
    </div>
    <div class="itembox">
      <router-link
        :to="{
          name: 'Product',
          params: { category: 1 },
          query: { productCategory: 1 },
        }"
        class="item">
          <figure
            :style="`background-image: url(${require('@/assets/img/index/img_tool_1.jpg')});`">
          </figure>
          <div class="cover"></div>
          <img src="@/assets/img/logo_1.png" class="easy_tag" alt="">
          <div class="text txt-white txt-center">
            <h5 class="line_title">WiFi 分享器</h5>
            <ul>
              <li class="p txt-white">
                <img src="@/assets/img/icon/icon_yes-w.svg" alt=""> 可熱點分享
              </li>
              <li class="p txt-white">
                <img src="@/assets/img/icon/icon_yes-w.svg" alt=""> 訊號穩定
              </li>
              <li class="p txt-white">
                <img src="@/assets/img/icon/icon_yes-w.svg" alt=""> 免換卡操作方便
              </li>
            </ul>
            <div class="btn_block">
              <button type="button" class="btn btn-orange">
                <h6>
                  <span>立即查看</span>&nbsp;
                  <img src="@/assets/img/icon/icon_exclamation-w.svg" alt="">
                </h6>
              </button>
            </div>
          </div>
      </router-link>
      <router-link
        :to="{
          name: 'Product',
          params: { category: 1 },
          query: { productCategory: 2 },
        }"
        class="item">
          <figure
            :style="`background-image: url(${require('@/assets/img/index/img_tool_2.jpg')});`">
          </figure>
          <div class="cover"></div>
          <img src="@/assets/img/logo_2.png" class="easy_tag" alt="">
          <div class="text txt-white txt-center">
            <h5 class="line_title">SIM 卡</h5>
            <ul>
              <li class="p txt-white">
                <img src="@/assets/img/icon/icon_yes-w.svg" alt=""> 隨插即用
              </li>
              <li class="p txt-white">
                <img src="@/assets/img/icon/icon_yes-w.svg" alt=""> 不需額外攜帶裝置
              </li>
              <li class="p txt-white">
                <img src="@/assets/img/icon/icon_yes-w.svg" alt=""> 用完即丟免歸還
              </li>
            </ul>
            <div class="btn_block">
              <button type="button" class="btn btn-orange">
                <h6>
                  <span>立即查看</span>&nbsp;
                  <img src="@/assets/img/icon/icon_exclamation-w.svg" alt="">
                </h6>
              </button>
            </div>
          </div>
      </router-link>
      <router-link
        :to="{
          name: 'Product',
          params: { category: 1 },
          query: { productCategory: 3 },
        }"
        class="item">
          <figure
            :style="`background-image: url(${require('@/assets/img/index/img_tool_3.jpg')});`">
          </figure>
          <div class="cover"></div>
          <img src="@/assets/img/logo_3.png" class="easy_tag" alt="">
          <div class="text txt-white txt-center">
            <h5 class="line_title">eSIM</h5>
            <ul>
              <li class="p txt-white">
                <img src="@/assets/img/icon/icon_yes-w.svg" alt=""> 無卡上網商務新選擇
              </li>
              <li class="p txt-white">
                <img src="@/assets/img/icon/icon_yes-w.svg" alt=""> 原門號可通話
              </li>
              <li class="p txt-white">
                <img src="@/assets/img/icon/icon_yes-w.svg" alt=""> 電子憑證取件
              </li>
              <li class="p txt-white">
                <img src="@/assets/img/icon/icon_yes-w.svg" alt=""> 掃描QR Code簡單方便
              </li>
            </ul>
            <div class="btn_block">
              <button type="button" class="btn btn-orange">
                <h6>
                  <span>立即查看</span>&nbsp;
                  <img src="@/assets/img/icon/icon_exclamation-w.svg" alt="">
                </h6>
              </button>
            </div>
          </div>
      </router-link>
    </div>
  </section>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/zh-cn';
import moment from 'moment';
import requestApi from '@/lib/http/index';
import WhSelect from '@/components/index/WhSelect.vue';
import 'vue2-datepicker/index.css';

export default {
  name: 'IndexEasy',
  components: {
    DatePicker,
    WhSelect,
  },
  data() {
    return {
      countries: [],
      flowTypes: [],
      searchData: {
        targetCountry: '',
        unspecifiedTime: false,
        dateRange: '',
        productCategory: '',
        flow: '',
      },
    };
  },
  methods: {
    search() {
      const {
        dateRange, flow, productCategory, targetCountry, unspecifiedTime,
      } = this.searchData;
      const searchForm = {
        unspecifiedTime: unspecifiedTime === false ? 0 : 1,
      };
      if (flow) {
        searchForm.flowType = flow;
      }
      if (productCategory) {
        searchForm.productCategory = productCategory;
      } else {
        searchForm.productCategory = this.$route.query.productCategory;
      }
      if (targetCountry && targetCountry.length > 0) {
        searchForm.countries = [];
        targetCountry.forEach((country) => {
          searchForm.countries.push(country);
        });
      }
      if (dateRange && dateRange.length > 0) {
        if (dateRange[0] && dateRange[1]) {
          const [abroadStart, abroadEnd] = dateRange;
          searchForm.abroad_start = moment(abroadStart).format('YYYY/MM/DD');
          searchForm.abroad_end = moment(abroadEnd).format('YYYY/MM/DD');
        }
      }
      const obj = {
        name: 'Product',
        params: { category: 1 },
        query: { ...searchForm },
      };
      this.$router.push(obj);
    },
    async getFilter() {
      const Countries = new Promise((resolve, reject) => {
        const getAreaWithCountries = requestApi('req.getAreaWithCountries');
        getAreaWithCountries.then((res) => {
          if (res.status) {
            const arr = [];
            res.data.forEach((area) => {
              // areas.forEach((area) => {
              if (area.name) {
                arr.push({ id: area.id, text: area.name, disabled: true });
              }
              if (area.countries.length > 0) {
                area.countries.forEach((country) => {
                  if (country.name && country.id) {
                    arr.push({ id: country.id, text: country.name, disabled: false });
                  }
                });
              }
              // });
            });
            resolve(arr);
          } else {
            reject(res.message);
          }
        });
      });
      const FlowTypes = new Promise((resolve, reject) => {
        const getflowTypes = requestApi('home.getflowTypes');
        getflowTypes.then((res) => {
          if (res.status) {
            const arr = [
              { id: '', text: '全部' },
            ];
            res.data.forEach((element) => {
              arr.push({
                id: element.id, text: element.name,
              });
            });
            resolve(arr);
          } else {
            reject(res.message);
          }
        });
      });

      Promise.all([Countries, FlowTypes]).then((values) => {
        const [countries, flowTypes] = values;
        this.countries = countries;
        this.flowTypes = flowTypes;
      });
    },
  },
  watch: {
    'searchData.noDateRange': function () {
      if (this.searchData.noDateRange === true) {
        this.searchData.dateRange = [];
      }
    },
  },
  mounted() {
    this.getFilter();
  },
};
</script>
