<template>
  <section id="discount">
    <div
      class="bg"
      :style="`background-image: url(${require('@/assets/img/icon/deco_exclamation_big.svg')})`">
    </div>
    <div class="bg oblique"></div>

    <div class="inner w1300">
      <div class="left">
        <h2
          v-scroll-reveal="$sr_rightSetting"
          class="h2-super_title txt-white txt-bold sr_right">
            強檔優惠立即掌握<br>
            服務快訊不錯過
            <img src="@/assets/img/icon/icon_exclamation-w.svg" alt="">
        </h2>
      </div>
      <div class="right">
        <div class="list">
          <router-link
            v-for="(item, key) in newsData"
            :key="key"
            :to="item.route"
            class="item">
              <p class="time txt-greenlake">{{item.publish_day}}</p>
              <p class="tag txt-blue">{{item.type}}</p>
              <h6>{{item.title}}</h6>
          </router-link>
        </div>
        <br>
        <div class="txt-right">
          <router-link :to="link" class="seemore txt-blue">
            查看更多&nbsp;
            <img src="@/assets/img/icon/icon_next.svg" alt="">
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PublicDiscountSection',
  props: ['newsData', 'link'],
  data() {
    return {
      // newsData: [
      //   {
      //     id: '01',
      //     date: '2020-03-22',
      //     type: '重要公告',
      //     title: '帛琉測試文章001',
      //   },
      // ],
    };
  },
};
</script>
