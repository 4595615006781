export default {
  /**
   * @name 登入
   */
  baseUrl: 'events',
  index: {
    url: '',
    token: false,
  },
  getCategories: {
    url: '/getCategories',
    token: false,
  },
  info: {
    url: '/info',
    token: false,
  },
  getRelationship: {
    url: '/getRelationship',
    token: false,
  },
};
