import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from '../views/Home.vue';
import News from '../views/News.vue';
import NewsInfo from '../views/NewsInfo.vue';
import Product from '../views/Product.vue';
import ProductInfo from '../views/ProductInfo.vue';
import CompanyArea from '../views/CompanyArea.vue';
import CompanyNews from '../views/CompanyNews.vue';
import CompanyInfo from '../views/CompanyInfo.vue';
import Events from '../views/Events.vue';
import EventInfo from '../views/EventInfo.vue';
import Blog from '../views/Blog.vue';
import BlogInfo from '../views/BlogInfo.vue';
import Faq from '../views/Faq.vue';
import Airport from '../views/Airport.vue';
import Member from '../views/Member.vue';
import Search from '../views/Search.vue';
import Terms from '../views/Terms.vue';

import requestApi from '@/lib/http';

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};
VueRouter.prototype.replace = function replace(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: '*', // 當連到不是我們自訂義的路由時
    redirect: '/', // 重新指向路由回首頁
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/Search',
    name: 'Search',
    props: true,
    component: Search,
  },
  {
    path: '/Terms/:type',
    name: 'Terms',
    props: true,
    component: Terms,
  },
  {
    path: '/Airport/:loc',
    name: 'Airport',
    props: true,
    component: Airport,
  },
  {
    path: '/Product/:category',
    name: 'Product',
    props: true,
    component: Product,
  },
  {
    path: '/Product/Info/:id',
    name: 'ProductInfo',
    props: true,
    component: ProductInfo,
  },
  {
    path: '/CompanyArea',
    name: 'CompanyArea',
    component: CompanyArea,
  },
  {
    path: '/CompanyNews',
    name: 'CompanyNews',
    component: CompanyNews,
  },
  {
    path: '/CompanyInfo/:id',
    name: 'CompanyInfo',
    component: CompanyInfo,
  },
  {
    path: '/Faq/:category',
    name: 'Faq',
    props: true,
    component: Faq,
  },
  {
    path: '/News',
    name: 'News',
    props: true,
    component: News,
  },
  {
    path: '/NewsInfo/:id',
    name: 'NewsInfo',
    props: true,
    component: NewsInfo,
  },
  {
    path: '/Events',
    name: 'Events',
    props: true,
    component: Events,
  },
  {
    path: '/EventInfo/:id',
    name: 'EventInfo',
    props: true,
    component: EventInfo,
  },
  {
    path: '/Blog',
    name: 'Blog',
    props: true,
    component: Blog,
  },
  {
    path: '/BlogInfo/:id',
    name: 'BlogInfo',
    props: true,
    component: BlogInfo,
  },
  {
    path: '/Member',
    // name: 'Member',
    component: Member,
    children: [
      {
        path: '',
        redirect: {
          name: 'MemberData',
        },
      },
      {
        path: 'Data',
        name: 'MemberData',
        meta: '會員資料',
        component: () => import('@/components/member/MemberData.vue'),
      },
      {
        path: 'ModifyPassword',
        name: 'MemberModifyPassword',
        meta: '修改密碼',
        component: () => import('@/components/member/MemberModifyPassword.vue'),
      },
      {
        path: 'Order',
        name: 'MemberOrder',
        meta: '訂單查詢',
        component: () => import('@/components/member/MemberOrder.vue'),
      },
      {
        path: 'Track',
        name: 'MemberTrack',
        meta: '追蹤清單',
        component: () => import('@/components/member/MemberTrack.vue'),
      },
      {
        path: 'Discount',
        name: 'MemberDiscount',
        meta: '我的優惠',
        component: () => import('@/components/member/MemberDiscount.vue'),
      },
      {
        path: 'Discount/NoShip',
        name: 'MemberDiscountNoShip',
        meta: '我的優惠',
        component: () => import('@/components/member/MemberDiscountNoShip.vue'),
      },
      {
        path: 'Notice',
        name: 'MemberNotice',
        meta: '我的通知',
        component: () => import('@/components/member/MemberNotice.vue'),
      },
    ],
  },
  {
    path: '/Member/Level',
    name: 'MemberLevel',
    props: true,
    component: () => import('@/views/MemberLevel.vue'),
  },
  {
    path: '/Member/OrderDetail/:id',
    name: 'OrderDetail',
    props: true,
    component: () => import('@/views/OrderDetail.vue'),
  },
  {
    path: '/Member/OrderQuestionnaire/:id',
    name: 'OrderQuestionnaire',
    props: true,
    component: () => import('@/views/OrderQuestionnaire.vue'),
  },
  {
    path: '/mail/OrderQuestionnaire/:id',
    name: 'MailOrderQuestionnaire',
    // props: true,
    component: () => import('@/views/OrderQuestionnaire.vue'),
  },
  {
    path: '/Login',
    name: 'Login',
    props: true,
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/Register',
    name: 'Register',
    props: true,
    component: () => import('@/views/Register.vue'),
  },
  {
    path: '/ForgetPassword',
    name: 'ForgetPassword',
    props: true,
    component: () => import('@/views/ForgetPassword.vue'),
  },
  {
    path: '/Cart',
    // name: 'Cart',
    props: true,
    component: () => import('@/views/Cart.vue'),
    children: [
      {
        path: '',
        redirect: {
          name: 'CartInfo',
        },
      },
      {
        path: 'Info',
        name: 'CartInfo',
        meta: '會員資料',
        component: () => import('@/components/cart/CartInfo.vue'),
      },
      {
        path: 'Detail',
        name: 'CartDetail',
        meta: '會員資料',
        component: () => import('@/components/cart/CartDetail.vue'),
      },
    ],
  },
  {
    path: '/Cart/Complete',
    name: 'CartComplete',
    props: true,
    component: () => import('@/views/CartComplete.vue'),
  },
  {
    path: '/Cart/Failed',
    name: 'CartFailed',
    props: true,
    component: () => import('@/views/CartFailed.vue'),
  },
  {
    path: '/Cart/Empty',
    name: 'CartEmpty',
    props: true,
    component: () => import('@/views/CartEmpty.vue'),
  },
  {
    path: '/logistic/familyMart',
    name: 'FamilyMartData',
    props: true,
    component: () => import('@/views/payment/FamilyMartData.vue'),
  },
  {
    path: '/linepay/confirm',
    name: 'LinepayConfirm',
    props: true,
    component: () => import('@/views/payment/LinepayConfirm.vue'),
  },
  {
    path: '/linepay/cancel',
    name: 'LinepayCancel',
    props: true,
    component: () => import('@/views/payment/LinepayCancel.vue'),
  },
  {
    path: '/payment/cathay/result',
    name: 'CathayResult',
    props: true,
    component: () => import('@/views/payment/CathayResult.vue'),
  },
  {
    path: '/About',
    name: 'About',
    props: true,
    component: () => import('@/views/About.vue'),
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  // },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const store = await import('@/store/index');

  const code = to.query?.code || from.query?.code;

  /** 驗證是否有隱形賣場code */
  const verifyMarketCode = async (route) => {
    const clearCart = async () => {
      const userID = store.default.state.user.id;
      if (userID) {
        const res = await requestApi('cart.clearCart', { member_id: userID });
        console.log('clear cart', res);
      }
    };
    const checkRedirect = (currentRoute) => {
      let routeData = null;
      if (currentRoute.name !== 'Home') {
        routeData = { name: 'Home' };
      }
      return routeData;
    };

    let result = null;

    if (code) {
      window.localStorage.setItem('marketCode', code);
    } else {
      const storageCode = window.localStorage.getItem('marketCode');

      if (storageCode) {
        window.localStorage.removeItem('marketCode');
        await clearCart();
        result = checkRedirect(route);
      }
    }
    return result;
  };

  const newRoute = await verifyMarketCode(to);

  if (newRoute) {
    next(newRoute);
  } else {
    next();
  }
});

export default router;
