export default {
  /**
   * @name 登入
   */
  baseUrl: 'cart',
  addCart: {
    url: '/addCart',
    method: 'post',
    token: false,
  },
  index: {
    url: '',
    token: false,
  },
  store: {
    url: '/store',
    method: 'post',
    token: false,
  },
  deleteItem: {
    url: '/deleteItem',
    token: false,
  },
  getRecommendProducts: {
    url: '/getRecommendProducts',
    token: false,
  },
  getMemberAddress: {
    url: '/getMemberAddress',
    method: 'post',
    token: false,
  },
  getHistoryAddress: {
    url: '/getHistoryAddress',
    method: 'post',
    token: false,
  },
  checkEnoughStock: {
    url: '/checkEnoughStock',
    method: 'post',
    token: false,
  },
  addPromotionEvent: {
    url: '/addPromotionEvent',
    method: 'post',
    token: false,
  },
  getPlusItem: {
    url: '/getPlusItem',
    token: false,
  },
  addPlusItem: {
    url: '/addPlusItem',
    method: 'post',
    token: false,
  },
  minusPlusItem: {
    url: '/minusPlusItem',
    token: false,
  },
  getPromotionEvent: {
    url: '/getPromotionEvent',
    token: false,
  },
  clearCart: {
    url: '/clearCart',
    token: false,
  },
};
