import sysVars from './sysVars';
import home from './home';
import newsApi from './newsApi';
import event from './event';
import articles from './articles';
import cooperates from './cooperates';
import faq from './faq';
import companyConsults from './company_consults';
import companyArticles from './company_articles';
import partners from './partners';
import req from './req';
import search from './search';
import product from './product';
import member from './member';
import cart from './cart';
import order from './order';
import promotion from './promotion';

export default {
  sysVars,
  home,
  newsApi,
  event,
  articles,
  cooperates,
  faq,
  companyArticles,
  partners,
  companyConsults,
  req,
  search,
  product,
  member,
  cart,
  order,
  promotion,
};
