var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('router-view'),(
      _vm.$route.name !== 'FamilyMartData'
      && _vm.$route.name !== 'LinepayConfirm'
      && _vm.$route.name !== 'LinepayCancel'
      && _vm.$route.name !== 'CathayResult'
    )?_c('Footer'):_vm._e(),(
      _vm.$route.name !== 'FamilyMartData'
      && _vm.$route.name !== 'LinepayConfirm'
      && _vm.$route.name !== 'LinepayCancel'
      && _vm.$route.name !== 'CathayResult'
    )?_c('Lightboxs'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }