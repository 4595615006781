<template>
  <div>
    <div class="title">
      <h3 class="h2-super_title txt-bold">
          隱私權聲明 <img src="@/assets/img/icon/icon_exclamation.svg" alt="">
      </h3>
    </div>

    <div class="textbox">
      <!-- <div class="top">
          <h6 class="txt-bold">Telecom Square 無線網路分享器租賃服務 (按天計費方案）使用基本規章</h6>
      </div> -->
      <div class="info">
        <!-- eslint-disable -->
          <p>
              特樂通股份有限公司（以下簡稱「本公司」）對於移動通訊裝置租賃業務、銷售業務以及媒體內容業務中蒐集之客戶地址、姓名等個人資料（包括客戶與本公司或與本公司的業務委託公司交易的有關情報，以下簡稱「個人資料」），依據《特樂通股份有限公司個人資料保護方針》及《株式会社Telecom Square個人資料保護方針》，採取以下處理方式。且本公司於蒐集未成年人之個人資料時，均已徵得其監護人同意。<br><br>
              ※未成年人：未滿20歲之台灣人，或依各國法令所規定之未成年人。
          </p><br><br>
          <p>
              一.    本公司對個人資料保護之立場<br><br>
              1.     遵守個人資料保護之相關法令、經濟產業領域之相關法令以及其他相關法令，以一般認定為公正妥當之個人資料處理慣例為依據妥善處理。並致力於適時改善處理方式。<br><br>
              2.     於本公司蒐集之個人資料可能適用（General Data Protection Regulation ＝ 歐盟一般資料保護法規，以下簡稱「GDPR」）時，本公司將依照GDPR妥善處理相關個人資料。<br><br>
              3.     蒐集客戶個人資料時，將明訂特定目的，並通知客戶或進行公告，依該特定目的處理及利用個人資料。<br><br>
              4.     處理或利用個人資料時，原則上依特定目的在必要範圍內使用，並明訂保存期間。除法令所認可之例外情形外，保存期間屆滿或特定目的消失後，立即刪除個人資料。<br><br>
              5.     為防止個人資料之洩漏、遺失、竄改等情事，本公司將採取必要安全措施並進行適當管理。<br><br>
              6.     本公司對於個人資料處理有關之投訴及建議，將即時誠摯地予以對應。若收到來自客戶本人對個人資料要求為目的之通知、公布、修改、追加、刪除、使用存取、加工以及停止公布時，將根據相關法令等予以處理。<br><br>
              7.     於本公司蒐集之個人資料將國際傳輸至日本，並儲存於本公司使用之日本伺服器。<br><br>
              8.     若對於本公司依GDPR處理個人資料之方式存有疑慮時，客戶可向EEA（European Economic Area）加盟國之監督機關提出異議。<br><br>
              9.     客戶即使已同意本公司規定之隱私權政策，仍可隨時撤回該同意。惟該同意之撤回將不會對撤回前執行完畢之個人資料利用、處理及國際傳輸之合法性造成影響。<br><br>
              10.   本公司為對應保護客戶個人資料所採取之適當安全措施、相關法令以及其他各類規範之變更，將適時、妥善並持續改進。<br><br>
              11.   於本個人資料保護方針有變更必要，且該變更與特定目的無關時，本公司得不經事前通知逕行變更。於官方網站上以明確方式公告之，並同時更新本個人資料保護方針之最後修改日。
          </p><br><br>
          <p>
              二.    符合本公司事業內容及規模之個人資料處理方式<br><br>
              本公司蒐集客戶個人資料時，將告知下列事項：<br><br>
              1.     蒐集個人資料之特定目的<br><br>
              1).    用於本公司經辦之移動通訊裝置租賃服務及其附加服務。<br><br>
              2).    用於本公司經辦之數據內容。<br><br>
              3).    用於銷售本公司經辦之商品。<br><br>
              4).    用於基於客戶興趣愛好的宣傳、廣告、營銷以及其它本公司之商品、服務營銷。<br><br>
              5).    用於與外界服務合作。<br><br>
              6).    用於實施問卷調查以及對調查結果之分析。<br><br>
              7).    用於經營分析所需之統計數字之製作及分析結果之利用。<br><br>
              8).    用於與合作廠商間之合約管理。<br><br>
              9).    用於本公司從業人員等之聘用（包含招募）以及人事管理<br><br>
              10).  用於與本公司合作之特定企業（包含無資本關係之企業）之產品與服務說明。<br><br>
              11).  依以下第五款所定之目的提供予第三人。<br><br>
              12).  用於防止非法契約、非法使用、拒付以及發生此行為之調查、對應等。<br><br>
              13).  其他執行前述各目相關業務所需之必要範圍<br><br>
              2.     個人資料之類別<br><br>
              姓名，性別，出生年月日，職業，聯絡方式，電子郵件，購買及租賃之商品等相關信息。<br><br>
              3.     個人資料之利用期間<br><br>
              於第1項所載特定目的之存續期間。<br><br>
              4.     個人資料之利用地區<br><br>
              「個人資料之利用對象」之國內及國外所在地。<br><br>
              5.     個人資料利用之對象<br><br>
              本公司及本公司之母公司株式会社Telecom Square及母公司之業務委託公司。<br><br>
              6.     個人資料之利用方式<br><br>
              符合個人資料保護法相關法令，以自動化或其他非自動化之機器或載具為儲存、處理等使用。<br><br>
              7.     依據個人資料保護法（以下簡稱「個資法」）第3條規定，客戶對本公司保有之個人資料得行使下列權利：<br><br>
              1).    除有個資法第10條所規定之情形外，得向本公司查詢、請求閱覽或請求給予複製本，本公司得依個資法第14條規定得酌收必要成本費用。<br><br>
              2).    得向本公司請求補充或更正，惟應依個資法施行細則第19條之規定，應適當釋明原因及事實。<br><br>
              3).    本公司如有違反個資法規定蒐集、處理或利用個人資料者，得依個資法第11條第4項規定向本公司請求停止搜集、處理或利用個人資料。<br><br>
              4).    當個人資料正確性有爭議時，客戶得依個資法第11條第2項請求本公司停止處理或利用。惟因該項但書規定，因本公司執行業務所必須，或經客戶書面同意並經註明其爭議者，不在此限。<br><br>
              5).    依個資法第11條第3項規定，當個人資料蒐集之特定目的消失或期限屆滿時，得向本公司請求刪除、停止處理或利用個人資料。惟依該項但書規定，因本公司執行業務所必須或經客戶書面同意者，不在此限。<br><br>
              6).    客戶得自由選擇是否提供相關個人資料，惟若拒絕提供時，本公司將無法提供相關服務。<br><br>
              8.     個人資料之修正、追加或刪除等<br><br>
              關於本公司保管之個人資料，若客戶要求修正、追加或刪除時，本公司應確認提出要求者為客戶本人，並對於與事實不一致之內容，於合理期間與範圍內進行修正、追加或刪除。
          </p><br><br>
          <p>
              三. 關於本公司Cookie的使用<br><br>
              本公司可能會透過Cookie或其它追蹤技術蒐集客戶個人資料。並且，本公司之官方網站、線上服務、會話軟體、電子郵件以及廣告等，亦採用Cookie或其它追蹤技術。<br><br>
              Cookie是指，將網站營運者所指定之信息（訪問瀏覽器之識別信息等），保存於瀏覽了該網站設備之機制。本公司將對本公司網站或伺服器之訪問情況進行確認，並且為方便客戶再次訪問，本公司亦將使用Cookie以調整網站構成。若客戶選擇繼續瀏覽本網站，則視為同意本《個人資料保護方針》。若客戶拒絕使用Cookie而更改了瀏覽器設置，可能導致無法使用本公司提供的網站使用環境以及部分其它服務之情形，敬請諒解。關於更改瀏覽器設置之相關疑問，請諮詢各瀏覽器廠商。
          </p><br><br>
          <p>
              四. 關於本公司的檔案安全<br><br>
              由本公司營運之所有需要輸入客戶個人資料之網站，均採用SSL（Secure Sockets Layer）加密技術。該技術能使輸入之訊息於客戶個人電腦中進行加密後，再透過網路完成註冊。<br><br>
              備註：SSL是指，透過瀏覽器與WWW伺服器之間的數據加密及身份認證，以防第三方竊取或篡改數據之技術。
          </p><br><br>
          <p>
              五.    關於本公司委託第三方處理個人資料<br><br>
              本公司於企業營運中，為銷售物品以及提供服務，於採取合乎個資法施行細則第8條規定對受委託第三人採取適當監督之情形下，有時將向第三方提供個人資料。
          </p><br><br>
          <p>
              六.    關於電話咨詢<br><br>
              本公司為了提高客戶應對力以及確認通話內容等客戶支援服務，得將對通話內容進行錄音。
          </p><br><br>
          <p>
              七.    關於匿名化資料的處理<br><br>
              1.     個人資料的匿名化<br><br>
              本公司將個人資料匿名化（透過法定處理，使個人資料不再具有識別性且無法還原）時，將遵循以下事項：<br><br>
              ・    遵循法律規定，實施適當處理。<br><br>
              ・    遵循法律規定進行相關安全處理，防止已刪除之資料以及資料之處理方法等洩漏。<br><br>
              ・    公開包含匿名化資料之項目。<br><br>
              ・    不透過匿名化前之個人資料識別特定個人。<br><br>
              2.     提供匿名化資料<br><br>
              本公司若需要將匿名化個人資料提供給第三方，則會公開包含匿名化個人資料之相關項目以及提供方法，且對該第三方明示該資料為匿名化個人資料。
          </p><br><br>
          <p>
              八.    私權聲明之諮詢<br><br>
              若您對本網站的隱私權聲明有任何問題，歡迎來信透過本公司網站【聯絡我們】洽詢。
          </p>
      </div>
    </div>
    <p class="txt-greenlake txt-right">2013 年 08 月 29 日 制定、施行</p>
  </div>
</template>
<script>
export default {
  name: 'Privacy',
};
</script>
