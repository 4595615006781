<template>
  <div>
    <Header/>
    <section id="member">
      <div class="inner w1400">
        <MemberSideNav/>
        <div class="show_area">
          <router-view></router-view>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import MemberSideNav from '@/components/member/MemberSideNav.vue';

import { checkStorage } from '@/utils/timerStorage';
import '@/assets/scss/member.scss';

export default {
  name: 'Member',
  components: {
    Header,
    MemberSideNav,
  },
  data() {
    return {
    };
  },
  methods: {
    checkIfLogin() {
      if (!checkStorage('token')) {
        this.$router.push({
          name: 'Home',
        });
      }
    },
  },
  mounted() {
    this.checkIfLogin();
  },
};
</script>
