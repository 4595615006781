<template>
  <section id="bolg_more-news">
    <div class="inner w1300">
      <h3 class="h2-super_title txt-bold">
          相關消息 <img src="@/assets/img/icon/icon_exclamation.svg" alt="">
      </h3>

      <div class="itembox">
        <router-link
          v-for="(item, key) in itemsArray"
          :key="key"
          :to="item.linkId"
          class="item">
            <p class="time txt-greenlake">{{item.date}}</p>
            <p class="tag txt-blue">{{item.category}}</p>
            <h6>{{item.title}}</h6>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ArticleMoreItemsLine',
  // itemsArray 會傳這樣的東西進來
  // [
  //   { linkId:'', date: '', category: '', title: '' },
  // ],
  props: ['itemsArray'],
  data() {
    return {
    };
  },
};
</script>
