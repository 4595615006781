<template>
  <div class="itembox">
    <router-link
      v-for="(item, key) in itemsArray"
      :key="key"
      :to="item.linkId"
      class="item">
      <figure
        :style="`background-image: url(${item.pic});`">
      </figure>
      <div class="text">
        <div class="datebox">
          <p class="time txt-greenlake">{{item.date}}</p>
          <p class="tag txt-blue">{{item.category}}</p>
        </div>
        <h6>{{item.title}}</h6>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'ArticleItems',
  // itemsArray 會傳這樣的東西進來
  // [
  //   { linkId:'', pic: '', date: '', category: '', title: '' },
  // ],
  props: ['itemsArray'],
  data() {
    return {
    };
  },
};
</script>
