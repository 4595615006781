<template>
  <div id="search">
    <div
      id="service_top"
      :style="`background-image:url(${require('@/assets/img/index/search.jpg')});`">
        <Header/>
        <div class="inner w1300">
            <div class="title txt-center sr_right">
                <p class="txt-blue txt-style-i">
                  Search
                  <img src="@/assets/img/icon/icon_exclamation.svg" alt="" style="width: .65em;">
                </p>
                <h2 class="h2-super_title txt-bold">
                  站內搜尋 <img src="@/assets/img/icon/icon_exclamation.svg" alt="">
                </h2>
            </div>
            <div class="searchbox">
                <div class="search_outer">
                    <input
                    type="text" class="h6"
                    v-model="inputData" placeholder="請輸入關鍵字">
                    <button type="button" @click="submit(inputData)">
                        <img src="@/assets/img/icon/icon_search.svg" alt="">
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div id="step">
        <div class="w1400 breadcrumbs">
            <span>
              <router-link to="/">
                首頁
              </router-link>
            </span> > 站內搜尋
        </div>
        <div class="inner w1400">
          <div class="bg">
              <div class="bg2" style="background-image: url(./img/feedback/deco.png)"></div>
          </div>

          <div class="search_itembox">
            <router-link
              v-for="(item, i) in itemsArray" :key="`result_${i}`"
              :to="item.link"
              class="item"
            >
              <div class="item_top">
                    <p class="time txt-greenlake">{{item.publishDay}}</p>
                    <p class="tag txt-blue">{{item.category}}</p>
                    <h6>{{item.title}}</h6>
                </div>
                <div class="item_bottom">
                    <h6 v-html="item.content">
                    </h6>
                </div>
            </router-link>
          </div>
          <Paginate/>
        </div>
      </div>
    </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import Paginate from '@/components/Paginate.vue';
import '@/assets/scss/service.scss';
import '@/assets/scss/product_info.scss';

import requestApi from '@/lib/http/index';

export default {
  name: 'Search',
  components: {
    Header,
    Paginate,
  },
  data() {
    return {
      inputData: '',
      itemsArray: [],
    };
  },
  methods: {
    async submit(keyword) {
      const result = await requestApi('search.index', { keyword });
      if (result.status) {
        const arr = [];
        result.data.forEach((element) => {
          const {
            id,
            // eslint-disable-next-line
            publish_day,
            category,
            title,
            content,
            menu,
          } = element;
          let link;
          switch (menu) {
            case '活動資訊':
              link = `/EventInfo/${id}`;
              break;
            case '生活講堂':
              link = `/BlogInfo/${id}`;
              break;
            case '企業專區':
              link = `/CompanyInfo/${id}`;
              break;
            case '最新消息':
              link = `/CompanyInfo/${id}`;
              break;
            default:
              break;
          }
          arr.push({
            publishDay: publish_day,
            category,
            title,
            content,
            link,
          });
        });
        this.itemsArray = arr;
      } else {
        this.$customSWAL({
          icon: 'error',
          title: '查無資料',
          text: '請查詢其他關鍵字',
          confirmButtonText: '確定',
        });
        this.itemsArray = [];
      }
    },
  },
};
</script>
