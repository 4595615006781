<template>
  <div>
    <div class="title">
        <h3 class="h2-super_title txt-bold">
            租賃條款 <img src="@/assets/img/icon/icon_exclamation.svg" alt="">
        </h3>
    </div>

    <div class="textbox">
        <div class="top">
            <h6 class="txt-bold">Telecom Square 無線網路分享器租賃服務 (按天計費方案）使用基本規章</h6>
        </div>
        <div class="info">
            <ul>
                <li>
                    <p class="txt-bold">
                        第1條【本服務的提供】
                    </p>
                    <p>
                      <!-- eslint-disable -->
                        特樂通股份有限公司（下稱本公司）提供無線網路分享器設備（下稱本設備）租賃服務（按天計費方案）（下稱本服務），供個人及法人（下稱簽約者）使用本服務，簽約者已完全了解並同意遵守本規章及關於本公司所介紹的特別規定事項的新規章、規定及注意事項（下稱個別規章），方申請本服務。
                    </p>
                </li>
                <li>
                    <p class="txt-bold">
                        第2條【關於本規章】
                    </p>
                    <p>
                        1．個別規章構成本規章的一部分。如本規章和個別規章的內容不一致，個別規章應優先予以適用。<br>
                        2．本公司保留修改本規章、使用費等及折扣適用等服務內容之權利，簽約者應遵循變更後的規章及服務內容。
                    </p>
                </li>
                <li>
                    <p class="txt-bold">
                        第3條【關於個人資訊處理的方針】
                    </p>
                    <p>
                        本公司對於從簽約者所獲得的個人資訊只用於本公司的隱私政策使用目的範圍之內，簽約者應同意後再申請本服務。<br>
                        詳細相關使用範圍與目的 >> <router-link class="txt-greenlake" to="/Terms/Privacy">點這裡</router-link>
                    </p>
                </li>
                <li>
                    <p class="txt-bold">
                        第4條【出示個人身份證件】
                    </p>
                    <p>
                        簽約者在申請本服務或領取本設備等時，應按本公司要求出示本人身份證件。
                    </p>
                </li>
                <li>
                    <p class="txt-bold">
                        第5條【申請】
                    </p>
                    <p>
                        1．簽約者按照本公司規定的方法申請本服務，於本公司以電子郵件或書面資料等適當的方法，通知簽約者已同意其申請時，即成立租賃合約。<br>
                        2．由於簽約者的自身原因，即使第1項申請審核的確認出現延遲，租賃合約仍成立。<br>
                        3．關於締約對象及本設備等之機型等，本公司保留最終決定權。<br>
                        4．簽約者以個人身分申請時，以 12台為限。<br>
                        5．根據合約內容（台數、期間等），本公司可能會向簽約者收取押金或信用卡授權（下稱保證金等）。
                    </p>
                </li>
                <li>
                    <p class="txt-bold">
                        第6條【取消申請和取消費】
                    </p>
                    <p>
                        1．在領取本設備等之前，簽約者得透過電子郵件、電話及FB私訊與本公司聯繫，取消本服務。<br>
                        2．於本設備等預定領取日的前3天內，為前項之取消者，本公司將收取每張訂單199元之手續費。<br>
                        3．簽約者未於預定領取日領取者，本公司依前項收取手續費。<br>
                        4．本公司得根據簽約者申請時所提出的保證金等，無需另經簽約者同意，即收取使用費、手續費等的結算費用。<br>
                    </p>
                </li>
                <li>
                    <p class="txt-bold">
                        第7條【申請內容的變更】
                    </p>
                    <p>
                        1．如送達目的地、出國目的地、出國期間等申請內容發生變更時，簽約者應立即向本公司提出變更之申請。<br>
                        2．如簽約者使用本設備等後，在預定返還日期之前返還時，視為已提出前項的申請。<br>
                        3．如在本設備等的預定領取日的3天以前，提出第1項規定之申請時，本公司予以辦理該變更手續 。但根據本設備等的庫存等狀況有時可能無法進行該變更。<br>
                        4．如在本設備等的預定領取日的前2天以內，提出第1項規定之申請時，本公司可能無法辦理該變更手續。<br>
                        5．若有提前歸還本設備等或變更合約租借期間，必須提供原始發票，否則一律不予退費。
                    </p>
                </li>
                <li>
                    <p class="txt-bold">
                        第8條【計費單位和計費期間】
                    </p>
                    <p>
                        1．合約按天計費，並以台灣時間為準。<br>
                        2．合約計費期間：從出國當日起，至回國當日止。<br>
                        3．合約計費開始日：簽約者親至機場等指定地點領取本設備之日；或宅配到貨日的第2天。到貨日期是指簽約者在申請時所指定出國日期的前1天。<br>
                        4．合約計費結束日：簽約者親至機場等指定地點返還本設備之日；若以宅配返還，以郵戳日期為準。<br>
                        5．逾期費用：若逾期返還，本公司將自逾期日起至返還日止，按每日租金乘以逾期天數之基準，收取逾期費用。此外，申請安心補償服務之簽約者，在逾期期間亦應支付此服務的相關費用。惟如因班機遲延、天災等不可歸責於簽約者之事由，導致逾期返還者，於檢附相關證明並經本公司審核許可者，得免計逾期罰金。
                    </p>
                </li>
                <li>
                    <p class="txt-bold">
                        第9條【終止】
                    </p>
                    <p>
                        1．簽約者的行為符合下列之一時，本公司得不予催告，立即終止。<br>
                        ① 當符合第5條【申請】第3項經本公司判斷不適申請時；<br>
                        ② 當符合第14條【簽約者的禁止事項】中規定的事項之一時；<br>
                        ③ 當簽約者超過預定返還日期7天以上，仍未與本公司任何聯繫而繼續使用本服務時；<br>
                        ④ 當簽約者拖欠支付費用或未盡義務時；<br>
                        ⑤ 當簽約者的信用狀況惡化或有充分理由認為其具有該可能時；<br>
                        ⑥ 其他嚴重有損合約當事人間的信任關係的行為時。<br>
                        2．凡出現前項的情況時，本公司得根據簽約者申請時所提出的保證金等，無需另經簽約者同意，即收取使用費等的結算費用。
                    </p>
                </li>
                <li>
                    <p class="txt-bold">
                        第10條【付款方式】
                    </p>
                    <p>
                        付款方式，原則上採用下列方式之一。<br>
                        ① 信用卡付款；<br>
                        ② 現金付款；<br>
                    </p>
                </li>
                <li>
                    <p class="txt-bold">
                        第11條【使用費及其他費用負擔】
                    </p>
                    <p>
                        1．本服務的使用費另行規定於本公司主頁及申請書等。<br>
                        2．在下列各項情況下，除前項規定的使用費外，簽約者還應負擔以下費用：<br>
                        ① 當本設備等出現遺失、失竊或破損時，依本公司規定賠償價款（申請加入「安心補償」者，依加入保險的規定賠償價款）；<br>
                        ② 如果延長租借日期或遺失損毀時，本公司得根據簽約者申請時所提出的保證金等，無需另經簽約者同意，即收取使用費等的結算費用。<br>
                        ③ 申請本服務後，無論是否使用都需依第1項規定支付使用費。<br>
                        ④ 使用本服務時，因當地通訊公司的問題導致訊號較弱無法使用時，也將發生費用。<br>
                        ⑤ 使用本服務時，若出現任何無法使用的情況，請於第一時間聯繫客服人員，若您未來電告知，視同簽約者自動放棄權益，不得異議。<br>
                        3．在下列各項情況下，除第1項規定的使用費外，簽約者還應負擔以下費用：<br>
                        ① 本服務依第9條終止後，因簽約者使用本設備所發生的通信等費用；<br>
                        ② 當本設備等遺失或失竊，且被第三人非法使用時，所發生的通信費用等（依第20條第2項通知本公司後，在線路實際停止前被非法使用時，亦同）；<br>
                        ③ 因簽約者未預計到的接收，使用本設備所生之通信費用等；<br>
                        ④ 因將本設備等用於電話會議等多方通話時，所生多條線路的通信費用等。<br>
                        4．如利用計時器型計費方式，通過電話卡、信用卡付費通話、免費電話，對方付費電話及預付卡等方法使用時，簽約者應按照時間來支付使用本設備等所發生的使用費。<br>
                        5．簽約者於台灣以外國家使用本設備，因聯繫日本客服人員或台灣客服人員時，所生的通信費用，由簽約者自行負擔。
                    </p>
                </li>
                <li>
                    <p class="txt-bold">
                        第12條【禁止轉讓權利義務】
                    </p>
                    <p>
                        簽約者不得將申請而產生的相關權利或義務轉讓給第三人或用於抵押的目的。
                    </p>
                </li>
                <li>
                    <p class="txt-bold">
                        第13條【注意妥善保管的義務】
                    </p>
                    <p>
                        簽約者應盡善良管理人之責，妥善保管本設備等。
                    </p>
                </li>
                <li>
                    <p class="txt-bold">
                        第14條【簽約者的禁止事項】
                    </p>
                    <p>
                        簽約者不得有下列行為：<br>
                        ① 犯罪行為或可能導致犯罪的行為；<br>
                        ② 違反公共秩序和善良風俗或其他違反法律、法規的行為或可能違反的行為；<br>
                        ③ 將本設備等進行拆卸、改造、修理、轉讓、出借、轉售、名義變更、抵押權的設定及其他提供擔保等的行為；<br>
                        ④ 將本設備等的通訊號碼刊載/標識到公開印刷出版物等的行為；<br>
                        ⑤ 取出本設備SIM卡的行為；<br>
                        ⑥ 更改本設備等的密碼的行為；<br>
                        ⑦ 違反本公司所定本設備使用方法的行為。
                    </p>
                </li>
                <li>
                    <p class="txt-bold">
                        第15條【簽約者的損害賠償責任】
                    </p>
                    <p>
                        1．對於簽約者在使用本服務時，因可歸責於簽約者而對本公司造成損害時，簽約者應負損害賠償之責。<br>
                        2．對於簽約者在使用本服務時，對第三人造成損害或與第三人發生糾紛時，簽約者應自負其責，解決因此而產生的費用等，本公司不負任何責任。

                    </p>
                </li>
                <li>
                    <p class="txt-bold">
                          第16條【本公司賠償範圍的限定】
                    </p>
                    <p>
                        因可歸責於本公司的事由而對簽約者造成損害時，對簽約者造成直接的或通常的損害，本公司應在本服務使用費的範圍內承擔責任，對於利益損失等特殊損害，無論是否具有可預見性本公司不負任何責任。
                    </p>
                </li>
                <li>
                    <p class="txt-bold">
                        第17條【與連接問題相關的本公司的責任範圍】
                    </p>
                    <p>
                        與本服務中的本設備等的連接問題（以下簡稱「連接問題」）相關的責任範圍，僅限於因本公司提供之本設備出現故障而導致的問題。因非可歸責於本公司之事由，導致的連接問題，本公司不承擔任何責任。
                    </p>
                </li>
                <li>
                    <p class="txt-bold">
                        第18條【資料通信的公平使用方針（公平使用原則）】
                    </p>
                    <p>
                        ① 為維護網路品質及公平使用電波，通信公司可能未經通知即限制或中斷網路流量，簽約者因此無法連接到無線網路或通信速度下降，本公司不承擔任何責任，簽約者仍應向本公司支付使用費等。<br>
                        ② Fair Usage Policy(國際數據通信公平利用政策)<br>
                        產品使用國家地區一般均有網路流量限制，申請人或使用人如因長時間觀看諸如網路電視、視訊、下載大流量檔案、網路遊戲等，造成無法上網或上網速度變慢，不得請求退費或損害賠償。
                    </p>
                </li>
                <li>
                    <p class="txt-bold">
                        第19條【關於使用停止的同意事項】
                    </p>
                    <p>
                        當符合下列之一時，本公司可能採取停止或暫停使用本服務的措施，簽約者應承擔在此期間所發生的使用費等。<br>
                        ① 符合第9條【終止】時；<br>
                        ② 符合第14條【簽約者的禁止事項】時；<br>
                        ③ 其他因技術上或本公司業務執行上等出現不得已的情況時。
                    </p>
                </li>
                <li>
                    <p class="txt-bold">
                        第20條【本設備等發生遺失、失竊或破損時】
                    </p>
                    <p>
                        1．當本設備等發生遺失、失竊或破損時，應承擔本公司既定的賠償費用。<br>
                        2．當本設備等發生遺失、失竊或破損時，應立即通知本公司。<br>
                        3．本公司在接到簽約者前項通知後，應立即向電信公司請求停止與本服務相關的通信線路。<br>
                        4．在前項情況下，線路實際停止前第三人非法使用時，簽約者應依第11條第3項負擔相關費用。
                    </p>
                </li>
                <li>
                    <p class="txt-bold">
                        第21條【安心補償】
                    </p>
                    <p>
                        1．本公司對服務期間的本設備等出現遺失、失竊或破損時，在既定的範圍提供安心補償。<br>
                        2．領取本設備等後，不能再申請或取消安心補償。<br>
                        3．安心補償僅用以在本設備等發生遺失、失竊或破損時賠償相關費用的部分，而不補償通信線路被停止為止的通信等費用等及由於遺失、失竊或破損而導致的簽約者的利益損失等。<br>
                        4．在加入了安心補償的情況下，當發生本設備等的遺失或失竊時，簽約者應提交當地員警署出具的失竊申報證明書或提交與此相關的證明該失竊的書面資料。
                    </p>
                </li>
                <li>
                    <p class="txt-bold">
                        第22條【其他簽約者的同意事項】
                    </p>
                    <p>
                        ① 簽約者於使用本服務時，並使用其自行持有的設備等進行資料漫遊時，可能另外收到簽約者所使用的電信公司發來的付費要求；<br>
                        ② 當簽約者為使用本服務而使用其自行持有的設備等時，本公司不對該機械設備的規格等一切運行提供保證以及不對使用目的提供適合性的保證；<br>
                        ③ 由於運輸公司的運輸延遲等原因，本設備等可能在希望領取日期之前無法送達簽約者等處。<br>
                        ④ 如因本設備鎖定，而需要本公司設置的密碼時，簽約者須將該本設備帶到本公司。<br>
                        ⑤ 同意接受本公司發送含行銷廣告在內的電子資訊。
                    </p>
                </li>
                <li>
                    <p class="txt-bold">
                        第23條【適用法律及管轄法院】
                    </p>
                    <p>
                        本規章中的適用法律均以台灣法為準，如本規章及合約產生糾紛，則合意以台灣台北地方法院為第一審專屬管轄法院。<br>
                        附則
                    </p>
                </li>
            </ul>
        </div>
    </div>
    <p class="txt-greenlake txt-right">2013 年 08 月 29 日 制定、施行</p>
  </div>
</template>
<script>
export default {
  name: 'Rent',
};
</script>
