/* https://syj0905.github.io/javascript/20191119/2134109134/ */
function checkTaxNumber(v) {
  /* 確認input代入的是純數字 */
  const checkIsNumber = parseInt(v, 10);
  if (Number.isNaN(checkIsNumber)) {
    // console.log('不是數字');
    return false;
  }
  /* 確認input代入的是純數字 */

  const guiNumber = v; // 取欄位內容

  const cx = [1, 2, 1, 2, 1, 2, 4, 1];
  const cnum = guiNumber.split('');
  let sum = 0;
  function cc(num) {
    let total = num;
    if (total > 9) {
      const s = total.toString();
      const n1 = s.substring(0, 1) * 1;
      const n2 = s.substring(1, 2) * 1;
      total = n1 + n2;
    }
    return total;
  }
  if (guiNumber.length !== 8) {
    // alert('統編錯誤，要有 8 個數字');
    return false;
  }
  // eslint-disable-next-line
  cnum.forEach((item, index) => {
    if (guiNumber.charCodeAt() < 48 || guiNumber.charCodeAt() > 57) {
      // alert('統編錯誤，要有 8 個 0-9 數字組合');
      return false;
    }
    sum += cc(item * cx[index]);
  });
  if (sum % 10 === 0) {
    // alert('統編正確');
    return true;
  } if (cnum[6] === '7' && (sum + 1) % 10 === 0) {
    // alert('統編正確2');
    return true;
  }
  return false;
}

export const required = (v) => !!v || false;
export const realName = (v) => /^[\u4e00-\u9fa5]{2,8}$/.test(v) || false;
export const realAge = (v) => ((Number(v) > 0) && v < 150) || false;
export const checkPhone = (v) => /^[0]?9[0-9]{2}[-]?[0-9]{3}[-]?[0-9]{3}$/im.test(v) || false;
// eslint-disable-next-line no-useless-escape
export const checkEgui = (v) => /^\/{1}[\d.A-Z0-9+-\.]{7}$/.test(v) || false;
export const checkMail = (v) => /^[A-Za-z0-9._\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(v) || false;
export const checkTel = (v) => /(\d{2,3}-?|\(\d{2,3}\))\d{3,4}-?\d{4}/.test(v) || false;
// export const checkCompanyNo = (v) => /^[0-9]{8}$/.test(v) || false;
// export const checkEgui = (v) => /^\/{1}[0-9A-Z]{7}$/.test(v) || false;
export const checkCompanyNo = (v) => checkTaxNumber(v) || false;
export const checkCertificated = (v) => /^[a-zA-Z]{2}[0-9]{14}$/.test(v) || false;
