<template>
  <section id="bolg_more-blog" v-if="itemsArray.length > 0">
    <div class="inner w1300">
      <h3 class="h2-super_title txt-bold">
        相關文章 <img src="@/assets/img/icon/icon_exclamation.svg" alt="">
      </h3>

      <swiper
        :options="swiperOption"
        class="itembox swiper-container">
          <swiper-slide v-for="(item, i) in itemsArray" :key="`more_${i}`">
            <router-link
            :to="item.linkId"
            class="item swiper-slide"
            >
              <figure
                :style="`background-image: url(${item.pic});`">
              </figure>
              <div class="text">
                <div class="datebox">
                  <p class="time txt-greenlake">{{item.date}}</p>
                  <p class="tag txt-blue">{{item.category}}</p>
                </div>
                <h6>{{item.title}}</h6>
              </div>
            </router-link>
          </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ArticleMoreItems',
  // itemsArray 會傳這樣的東西進來
  // [
  //   { linkId:'', pic: '', date: '', category: '', title: '' },
  // ],
  props: ['itemsArray'],
  data() {
    return {
      swiperOption: {
        loop: false,
        slidesPerView: 3,
        spaceBetween: 10,
        allowTouchMove: true,
        pagination: {
          el: '#bolg_more-blog .swiper-pagination',
          clickable: true,
        },
        breakpoints: {
          425: {
            slidesPerView: 1,
          },
          880: {
            slidesPerView: 2,
          },
        },
      },
    };
  },
};
</script>
