<template>
  <div>
    <swiper
      :options="options"
      class="picset swiper-container"
      >
        <swiper-slide  class="swiper-wrapper">
            <router-link
              :to="{ name: 'ProductInfo', params: { id: id } }"
              class="swiper-slide" :style="`background-image: url(${cover})`">
            </router-link>
        </swiper-slide>
        <swiper-slide v-for="(colorImg, i) in images"
          :key="`color_${i}`"
          class="swiper-wrapper">
            <router-link
              :to="{ name: 'ProductInfo', params: { id: id } }"
              class="swiper-slide" :style="`background-image: url(${colorImg.img})`">
            </router-link>
        </swiper-slide>
    </swiper>
    <div
      class="arrow"
      :class="`arrow-${id}`"
    >
      <div class="to_right">
        <img src="@/assets/img/icon/icon_next-g.svg" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductItemSwiper',
  props: {
    id: {
      type: String,
      default: String(Math.ceil(Math.random() * 9999999999999)),
    },
    cover: {
      type: String,
    },
    images: {
      type: Array,
    },
  },
  data() {
    return {

    };
  },
  computed: {
    options() {
      return {
        loop: true,
        allowTouchMove: true,
        navigation: {
          nextEl: `#product .item .item_show .travel_pic .arrow.arrow-${this.id}`,
        },
      };
    },
  },
  methods: {

  },
};
</script>
