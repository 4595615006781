export default {
  /**
   * @name 登入
   */
  baseUrl: 'company_articles',
  getLastArticle: {
    url: '/getLastArticle',
    method: 'get',
    token: false,
  },
  index: {
    url: '',
    method: 'get',
    token: false,
  },
  getTypes: {
    url: '/getTypes',
    method: 'get',
    token: false,
  },
  info: {
    url: '/info',
    token: false,
  },
  getRelationship: {
    url: '/getRelationship',
    token: false,
  },
};
