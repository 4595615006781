<template>
  <div id="News">
    <Header/>

    <div id="step" class="terms">
        <div class=" w1400 breadcrumbs">
          <span>
            <router-link to="/">首頁</router-link>
          </span> > {{log}}
        </div>
        <div class="inner w1400">

          <ArticleTag
            :currentIndex="currentIndex"
            :categoryArray="categoryArray"/>

          <Copyright v-if="currentPage === 'Copyright'" />

          <Privacy v-if="currentPage === 'Privacy'" />

          <Rent v-if="currentPage === 'Rent'" />

          <TurnBack v-if="currentPage === 'TurnBack'" />
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import ArticleTag from '@/components/article/ArticleTag.vue';

import Copyright from '@/components/terms/Copyright.vue';
import Privacy from '@/components/terms/Privacy.vue';
import Rent from '@/components/terms/Rent.vue';
import TurnBack from '@/components/terms/TurnBack.vue';

import '@/assets/scss/service.scss';

export default {
  name: 'Terms',
  components: {
    Header,
    ArticleTag,
    Copyright,
    Privacy,
    Rent,
    TurnBack,
  },
  data() {
    return {
      log: '',
      currentPage: 'Copyright',
      currentIndex: 0,
      categoryArray: [
        {
          id: 'Copyright',
          name: '著作權聲明',
          link: '/Terms/Copyright',
        },
        {
          id: 'Privacy',
          name: '隱私權聲明',
          link: '/Terms/Privacy',
        },
        {
          id: 'Rent',
          name: '租賃條款',
          link: '/Terms/Rent',
        },
        {
          id: 'TurnBack',
          name: '退貨說明',
          link: '/Terms/TurnBack',
        },
      ],
    };
  },
  methods: {
    getData(type) {
      this.currentPage = type;
    },
    changeCategoryIndexAndLog(type) {
      this.categoryArray.forEach((item, i) => {
        if (item.id === type) {
          this.currentIndex = i;
          this.log = item.name;
        }
      });
    },
  },
  watch: {
    $route(to, from) {
      if (to !== from) {
        this.currentPage = to.params.type;
        this.changeCategoryIndexAndLog(to.params.type);
      }
    },
  },
  mounted() {
    this.getData(this.$route.params.type);
    this.changeCategoryIndexAndLog(this.$route.params.type);
  },
};
</script>
