export default {
  /**
   * @name 登入
   */
  baseUrl: 'partners',
  getPartnerLogos: {
    url: '/getPartnerLogos',
    token: false,
  },
};
