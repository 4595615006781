export default {
  /**
   * @name 登入
   */
  baseUrl: 'order',
  linePayConfirm: {
    url: '/linePayConfirm',
    // method: 'post',
    token: false,
    data: {
      transactionId: true,
      orderId: true,
    },
  },
  linePayCancel: {
    url: '/linePayCancel',
    // method: 'post',
    token: false,
    data: {
      orderId: true,
    },
  },
};
