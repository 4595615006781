export default {
  /**
   * @name 登入
   */
  baseUrl: 'search',
  index: {
    url: '',
    method: 'post',
    token: false,
  },
};
