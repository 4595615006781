import { setStorage } from '../utils/timerStorage';

// 登入, 登出
export const setIsLogin = (state, status) => {
  setStorage('isLogin', status);
  state.isLogin = status;
};

// 設置會員資料
export const setUserInfo = (state, info) => {
  setStorage('user', info);
  state.user = info;
};

// 檢查是否有填寫完整資料
export const setMemberInfo = (state, status) => {
  state.isMemberInfo = status;
};

// loading
export const setIsLoading = (state, status) => {
  state.isLoading = status;
};

export const coopLightboxStatusChange = (state, toStatus) => {
  state.lightboxStatus.coopLightbox = toStatus;
};

export const companyLightboxStatusChange = (state, toStatus) => {
  state.lightboxStatus.companyLightbox = toStatus;
};

export const setToolSearch = (state, data) => {
  state.toolSearch = data;
};

export const setCart = (state, data) => {
  state.cart = data;
};

export const setTotalPriceAndCount = (state, data) => {
  state.totalPriceAndCount = data;
};

export const setTakeway = (state, data) => {
  state.takeWay = data;
};

export const setOrder = (state, data) => {
  state.order = data;
};

export const setOrderDetail = (state, data) => {
  state.orderDetail = data;
};

export const setAbroadAt = (state, data) => {
  state.abroad_at = data;
};

export const setBackhomeAt = (state, data) => {
  state.backhome_at = data;
};

export const setAcceptRefund = (state, data) => {
  state.acceptRefund = data;
};

export const setAcceptTerms = (state, data) => {
  state.acceptTerms = data;
};

export const resetOrderData = (state) => {
  state.cart = 0;
  state.totalPriceAndCount = {
    count: 0,
    total: 0,
  };
  state.takeWay = null;
  state.order = null;
  state.orderDetail = null;
  state.abroad_at = null;
  state.backhome_at = null;
  state.acceptRefund = false;
  state.acceptTerms = false;
};

export const updateCanCartToDetail = (state, data) => {
  // 如果是上網工具
  if (data.currentCartType === 1) {
    // 如果是租借品
    if (data.cartsHasRent === 1) {
      if (
        data.cartForm.flight_abroad
        && data.cartForm.flight_abroad !== 'null'
        && data.cartForm.return_option_id
        && data.cartForm.return_way_id
        && data.cartForm.take_option_id
        && data.cartForm.take_way_id) {
        state.canCartToDetail = true;
      } else {
        state.canCartToDetail = false;
      }
      // 如果是出售品
    } else if (data.cartsHasRent === 2) {
      if (
        data.cartForm.flight_abroad
        && data.cartForm.flight_abroad !== 'null'
        && data.cartForm.take_option_id
        && data.cartForm.take_way_id) {
        state.canCartToDetail = true;
      } else {
        state.canCartToDetail = false;
      }
    }
    // 如果是一般商品
  } else if (data.currentCartType === 2) {
    if (
      data.cartForm.take_option_id
      && data.cartForm.take_way_id) {
      state.canCartToDetail = true;
    } else {
      state.canCartToDetail = false;
    }
  }
};

export const setPlusHasWifi = (state, status) => {
  state.plusHasWifi = status;
};
