export default {
  /**
   * @name 系統參數
   */
  baseUrl: '/sysVars',
  index: {
    url: '',
    token: false,
    // token: true,
  },
};
