<template>
  <section
    id="choose_wiho"
    class="main_padding"
    :style="`background-image: url(${require('@/assets/img/index/img_sky.jpg')});`">
      <div class="inner w1300">
        <div class="text">
          <h2
            v-scroll-reveal="$sr_rightSetting"
            class="h2-super_title txt-bold">
            <!-- 第一次使用？<br> -->
            出國上網選擇<span class="txt-highbold txt-style-i">Wi-Ho!</span>準沒錯
            <img src="@/assets/img/icon/icon_exclamation.svg" alt="">
          </h2>
        </div>
      </div>

      <swiper
        class="itembox"
        v-if="this.feedbacks.length > 0"
        :options="swiperOption">
          <swiper-slide
            class="item"
            v-for="(item, key) in this.feedbacks"
            :key="key">
              <div class="top">
                <img src="@/assets/img/icon/icon_quotation_mark.svg" alt="">
                <div class="star_box">
                  <p class="txt-lightblue">整體滿意度</p>
                  <div>
                    <img
                      v-for="n in Math.floor(item.rate)"
                      :key="n"
                      src="@/assets/img/index/icon_star.svg" alt="">
                  </div>
                </div>
              </div>
              <h6 class="text_show">{{item.info}}</h6>
              <div class="type">
                <p class="txt-lightblue">
                  <template v-if="item.customer">
                    {{item.customer.substr(0, 1)}}
                  </template>
                  <template v-if="item.sex === 'm'">
                    先生
                  </template>
                  <template v-if="item.sex === 'f'">
                    小姐
                  </template>
                </p>
                <p>{{item.product}}</p>
              </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
</section>
</template>

<script>
export default {
  name: 'IndexChooseWiho',
  props: ['feedbacks'],
  data() {
    return {
      // feedbacks: [
      //   {
      //     rate: 5,
      //     info: '讚讚讚',
      //     product: '日本藍鑽石',
      //     customer: '高雄王小姐',
      //   },
      // ],
      swiperOption: {
        loop: false,
        slidesPerView: 3.5,
        spaceBetween: 20,
        allowTouchMove: true,
        autoplay: {
          delay: 3000,
        },
        pagination: {
          el: '#choose_wiho .swiper-pagination',
          clickable: true,
        },
        breakpoints: {
          425: {
            slidesPerView: 1,
          },
          600: {
            slidesPerView: 1.5,
          },
          880: {
            slidesPerView: 2,
          },
          1023: {
            slidesPerView: 2.5,
          },
        },
      },
    };
  },
};
</script>
