/**
 * request api 封裝
 */
import axios from 'axios';
import qs from 'qs';

import httpList from './api';

// debug模式
const isDev = process.env.NODE_ENV === 'development';

let AUTH_TOKEN = null;

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    // TODO: logout
    if (error?.response) {
      return error.response;
    }
    return {
      data: {
        status: false,
        message: '伺服器無回應，請檢查您的網路',
        data: null,
      },
    };
  },
);

export const setToken = (token = null) => {
  AUTH_TOKEN = token;
};

// 遞迴
function buildFormData(formData, data, parentKey) {
  if (
    data
    && typeof data === 'object'
    && !(data instanceof Date)
    && !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key,
      );
    });
  } else {
    const value = data == null ? '' : data;
    formData.append(parentKey, value);
  }
}

function toFormData(data) {
  const formData = new FormData();

  buildFormData(formData, data);
  return formData;
}

function request(
  url,
  data = false,
  method = 'get',
  token = false,
  headers = {},
  requestConfig = {},
  download = false,
) {
  let newHeaders = headers;
  if (token) {
    if (AUTH_TOKEN === null) {
      return new Promise((resolve /* , reject */) => {
        resolve({
          status: false,
          message: '本地token無效',
          data: null,
        });
      });
    }
    newHeaders = { ...newHeaders, authorization: `Bearer ${AUTH_TOKEN}` };
  }
  let req = {
    url,
    method,
    headers: newHeaders,
    Accept: 'application/json, text/plain, */*',
    mode: 'no-cors',
    timeout: 20000,
    ...requestConfig,
  };

  if (download) {
    req = { ...req, responseType: 'blob' };
  }

  if (req.method === 'post' && data !== false) {
    newHeaders = {
      ...newHeaders,
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    req = { ...req, data: qs.stringify(data), headers: newHeaders };
  }

  if (req.method === 'file' && data !== false) {
    newHeaders = {
      ...newHeaders,
      'Content-Type': 'multipart/form-data',
      // 'Content-Type': 'application/x-www-form-urlencoded',
    };
    req = {
      ...req, method: 'post', data: toFormData(data), headers: newHeaders,
    };
  }

  if (req.method === 'straas' && data !== false) {
    newHeaders = {
      ...newHeaders,
      ...req.headerSettings,
    };
    req = {
      ...req, method: 'put', data: data.file, headers: newHeaders,
    };
  }

  if (['get'].includes(req.method) && data !== false) {
    req = { ...req, params: data, headers: newHeaders };
  }

  // if (isDev) console.info(`⭕ http req ${req.url}`, req);

  return axios(req)
    .then((response) => {
      if (isDev) {
        // console.info(`⭕ http response ${req.url}`, response.data);
      }
      return response.data;
    })
    .catch((err) => {
      if (isDev) {
        console.log('❌ http err', err.response.data);
      }
      return err.response.data;
    });
}

export default function main(name = null, options = {}, requestConfig = {}) {
  if (!name) {
    return null;
  }

  const [controller = '', action = 'index'] = name.split('.');

  const moduleSpecifier = httpList[controller] ?? {};

  const apiSpecifier = moduleSpecifier[action] ?? {};

  let url = '';
  // url = `https://automation-api.howdesign.com.tw${moduleSpecifier.baseUrl}${apiSpecifier.url}`;
  url = `${process.env.VUE_APP_API_KEY}${moduleSpecifier.baseUrl}${apiSpecifier.url}`;
  if (/^http/.test(name)) url = name;

  const {
    params = {},
    fun = null,
    method = 'get',
    header = {},
    token = true,
    download = false,
  } = apiSpecifier;

  const data = { ...params, ...options };

  return request(url, data, method, token, header, requestConfig, download).then((response) => {
    // if (isDev) console.info('👉👉 response', response);
    if (fun && typeof fun === 'function') {
      return fun(response);
    }
    return response;
  });
}
