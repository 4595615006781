<template>
  <section id="icon_block">
    <div class="w1300">
      <h2
        v-scroll-reveal="$sr_rightSetting"
        class="h2-super_title txt-bold">
        合作夥伴
        <img src="@/assets/img/icon/icon_exclamation.svg" alt="">
      </h2>
    </div>

    <swiper
      v-if="logos.length !== 0"
      :options="swiperOption"
      class="icon_block block_top">
      <swiper-slide
        v-for="(item, i) in logos"
        :key="`logo_${i}`">
          <!-- <a class="item" href='' target="_blank">
            <figure style="background-image: url();"></figure>
          </a> -->
          <div class="item">
            <figure
              :style="{backgroundImage: 'url(' + item.img + ')' }">
            </figure>
          </div>
      </swiper-slide>
    </swiper>
  </section>
</template>

<script>
import requestApi from '@/lib/http/index';

export default {
  name: 'CompanyIconBlock',
  data() {
    return {
      logos: [],
      swiperOption: {
        loop: true,
        slidesPerView: 5,
        spaceBetween: 0,
        allowTouchMove: true,
        // slidesOffsetBefore : offset,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        breakpoints: {
          425: {
            slidesPerView: 2,
          },
          600: {
            slidesPerView: 3,
          },
          1023: {
            slidesPerView: 4,
          },
        },
      },
    };
  },
  methods: {
    async getData() {
      this.logos = [];
      const result = await requestApi('partners.getPartnerLogos');
      if (result.status) {
        result.data.forEach((element) => {
          const item = {
            img: element.img,
          };
          this.logos.push(item);
        });
      }
      if (this.logos.length < 6) {
        this.logos = [
          ...this.logos,
          ...this.logos,
          ...this.logos,
          ...this.logos,
          ...this.logos,
          ...this.logos,
        ];
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
