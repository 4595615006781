import Vue from 'vue';

// Swiper
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.css';
// vue-scroll-reveal
import VueScrollReveal from 'vue-scroll-reveal';
// sweetalert2
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
// vue-cookies
import VueCookies from 'vue-cookies';
// vSelect
import vSelect from 'vue-select';
// Select2
import Select2 from 'v-select2-component';
// VueNumeric(價格字符換算顯示套件)
import VueNumeric from 'vue-numeric';
// VueCardFormat
import VueCardFormat from 'vue-credit-card-validation';
// import DateRangePicker from 'vue2-daterange-picker';
// import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

import App from './App.vue';
import router from './router';
import store from './store';

// eslint-disable-next-line no-multi-assign
window.$ = window.jQuery = require('jquery');

Vue.config.productionTip = false;

Vue.use(VueAwesomeSwiper);
Vue.use(VueSweetalert2);
Vue.use(VueCookies);
Vue.use(VueNumeric);
Vue.use(VueCardFormat);
Vue.use(VueScrollReveal, {
  viewFactor: 0.5,
  duration: 500,
  distance: '50px',
  opacity: 0,
  scale: 1,
  easing: 'linear',
  mobile: true,
  reset: false,
});

Vue.component('v-select', vSelect);
Vue.component('Select2', Select2);
// Vue.component('DateRangePicker', DateRangePicker);
new Vue({
  router,
  store,
  render: (h) => h(App),
  created() {
    // sweetalert2 lightbox訊息
    // eslint-disable-next-line no-proto
    this.__proto__.$customSWAL = (
      {
        icon = 'success',
        title = '',
        text = null,
        html = null,
        thenFunc = null,
        confirmButtonText = '確定',
      } = {},
    ) => {
      this.$swal.fire({
        position: 'center-center',
        icon,
        title: title || '訊息',
        text,
        html,
        confirmButtonText,
        showClass: { popup: 'animated fadeInDown faster' },
        hideClass: { popup: 'animated fadeOutUp faster' },
      }).then(() => {
        if (thenFunc) {
          thenFunc();
        }
      });
    };
    // scroll-reveal的客制共用設定, 可以在使用的時候調用, Ex: v-scroll-reveal="$sr_XXX"
    // eslint-disable-next-line no-proto
    this.__proto__.$sr_rightSetting = {
      origin: 'right',
    };
    // eslint-disable-next-line no-proto
    this.__proto__.$sr_leftSetting = {
      origin: 'left',
    };
    // eslint-disable-next-line no-proto
    this.__proto__.$sr_bottomSetting = {
      origin: 'bottom',
    };
  },
}).$mount('#app');
