export default {
  /**
   * @name 登入
   */
  baseUrl: 'home',
  getBanners: {
    url: '/getBanners',
    token: false,
  },
  getLastNews: {
    url: '/getLasNews',
    token: false,
  },
  getQuestionnaires: {
    url: '/getQuestionnaires',
    token: false,
  },
  getLastEvents: {
    url: '/getLastEvents',
    token: false,
  },
  getflowTypes: {
    url: '/getflowTypes',
    token: false,
  },
  // index: {
  //   url: '',
  //   method: 'post',
  //   data: {
  //     username: true,
  //     password: true,
  //   },
  //   token: false,
  // },
};
