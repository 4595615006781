<template>
  <section id="kv">
    <swiper
      :options="swiperOption"
      v-if="computedKvData.length"
      class="kv_box swiper-container">
      <swiper-slide
        v-for="(item, key) in computedKvData"
        :key="key">
        <a
          :href="item.link === null ? '#' : item.link"
          :style="{backgroundImage: `url(${item.bg})`}"
          class="kv_item">
            <!-- <div class="text txt-white">
              <div>
                <h2 class="h1 txt-bold">
                  跟著<span class="txt-highbold txt-style-i">Wi-Ho!</span><br>一起去日本
                </h2>
              </div>
              <img src="@/assets/img/index/bage_KV.png" class="m768-off" alt="">
            </div>
            <img src="@/assets/img/index/bage_KV.png" class="m768-on" alt=""> -->
        </a>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </section>
</template>

<script>
export default {
  name: 'IndexKv',
  props: ['kvData'],
  data() {
    return {
      windowWidth: window.innerWidth,
      swiperOption: {
        loop: false,
        slidesPerView: 1,
        spaceBetween: 0,
        allowTouchMove: true,
        autoplay: true,
        delay: 5000,
        speed: 2000,
        pagination: {
          el: '#kv .swiper-pagination',
          clickable: true,
        },
      },
    };
  },
  computed: {
    computedKvData() {
      const temp = [];

      for (let i = 0; i < this.kvData.length; i += 1) {
        if (this.windowWidth > 768) {
          temp.push({
            bg: this.kvData[i].bg,
            link: this.kvData[i].link,
          });
        } else {
          temp.push({
            bg: this.kvData[i].bgMobile,
            link: this.kvData[i].link,
          });
        }
      }

      return temp;
    },
  },
};
</script>
