export default {
  /**
   * @name 登入
   */
  baseUrl: 'faq',
  getFaqs: {
    url: '/getFaqs',
    token: false,
  },
  getTypes: {
    url: '/getTypes',
    token: false,
  },
};
