<template>
  <article>

      <div class="pic_module pic_center module" v-if="item.main.banner">
          <img :src="item.main.banner" alt="">
      </div>

      <div class="article_title txt-center" v-if="item.main">
        <p class="txt-blue">{{item.main.category}}</p>
        <h1 class="txt-bold h3">{{item.main.title}}</h1>
        <p class="txt-greenlake">{{item.main.publishDay}}</p>
      </div>

      <!-- v-html -->
      <div class="content module" v-if="item.content" v-html="item.content" />

      <!-- 左文右圖 Style ID 6 -->
      <div class="left_text-right_pic_module module" v-else-if="item.ltrp">
        <div class="text">
          <h5>title</h5><br>
          <h6>sub_title</h6><br>
          <p>description</p>
        </div>
        <div class="pic">
          <img src="" alt="">
        </div>
      </div>
      <!-- 右文左圖 Style ID 5 -->
      <div class="right_text-left_pic_module module" v-else-if="item.rtlp">
        <div class="text">
          <h5>title</h5><br>
          <h6>sub_title</h6><br>
          <p>description</p>
        </div>
        <div class="pic">
          <img src="" alt="">
        </div>
      </div>
      <!-- 標題與文字 Style ID 1 -->
      <div class="text_module module txt-center" v-else-if="item.textModule">
        <h5>title</h5><br>
        <h6>sub_title</h6><br>
        <p>description</p>
      </div>
      <!-- 不滿版大圖 Style ID 3 -->
      <div class="pic_module module" v-else-if="item.picModule">
        <img src="" class="pc_img" alt="">
        <img src="" class="mo_img" alt="">
      </div>
      <!-- 文字編輯器 Style ID 2 -->
      <div class="text_module module" v-else-if="item.description">
        description
      </div>

      <div class="btnbox txt-center">
          <span href="" class="btn btn-blue" @click="back">
              <p>返回總覽</p>
          </span>
      </div>

  </article>
</template>

<script>
export default {
  name: 'ArticleInfo',
  props: ['item'],
  data() {
    return {
    };
  },
  methods: {
    back() {
      if (this.$route.name === 'EventInfo') {
        this.$router.push({ name: 'Events' });
      } else if (this.$route.name === 'BlogInfo') {
        this.$router.push({ name: 'Blog' });
      } else if (this.$route.name === 'NewsInfo') {
        this.$router.push({ name: 'News' });
      } else if (this.$route.name === 'CompanyInfo') {
        this.$router.push({ name: 'CompanyNews' });
      } else {
        this.$router.back();
      }
    },
  },
};
</script>
