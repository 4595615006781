export default {
  /**
   * @name 登入
   */
  baseUrl: 'req',
  getAreaWithCountries: {
    url: '/getAreaWithCountries',
    token: false,
  },
  getProductCategories: {
    url: '/getProductCategories',
    token: false,
  },
  getTakeWays: {
    url: '/getTakeWays',
    token: false,
  },
  getReturnWay: {
    url: '/getReturnWay',
    token: false,
  },
  getTakeWayByProducts: {
    url: '/getTakeWayByProducts',
    token: false,
    method: 'post',
  },
  getReturnWayByProducts: {
    url: '/getReturnWayByProducts',
    token: false,
    method: 'post',
  },
  getCounty: {
    url: '/getCounty',
    token: false,
  },
  getDistrict: {
    url: '/getDistrict',
    token: false,
  },
  getStreets: {
    url: '/getStreets',
    token: false,
  },
  getRanges: {
    url: '/getRanges',
    token: false,
  },
  getZipcode: {
    url: '/getZipcode',
    token: false,
  },
  getCountryPhoneCode: {
    url: '/getCountryPhoneCode',
    token: false,
  },
  getPayments: {
    url: '/getPayments',
    token: false,
  },
  getDonateOrgs: {
    url: '/getDonateOrgs',
    token: false,
  },
  getBrands: {
    url: '/getBrands',
    token: false,
  },
  getMobileModelByBrand: {
    url: '/getMobileModelByBrand',
    token: false,
  },
  getShoppingMoney: {
    url: '/getMemberRemainShoppingMoney',
    token: true,
  },
};
