<template>
  <!-- 分類區塊 -->
  <div
    :class="{
      product_wifi : productCategory === 1,
      product_sim : productCategory === 2,
      product_esim : productCategory === 3,
      product_other : productCategory >= 4,
    }"
    class="product_area main_padding">
    <div class="fix_bg"><figure></figure></div>
    <div class="inner">
      <!-- 優惠標語 ＆ 分類內排序 -->
      <div class="inner_title">
        <div class="oblique_tag">
          <h5 class="txt-white" v-if="productDiscountTag">
            {{productDiscountTag}}&nbsp;
            <img src="@/assets/img/icon/icon_exclamation-w.svg" alt="">
          </h5>
        </div>
        <div class="sort_wifi">
          <p class="txt-white">排序</p>
          <select
            v-model="sortType"
            id="productSort"
            class="p">
              <option value="0" :default="{true: sortType == 0}">請選擇</option>
              <option value="1" :default="{true: sortType == 1}">價格低 → 高</option>
              <option value="2" :default="{true: sortType == 2}">人氣高 → 低</option>
          </select>
        </div>
      </div>

      <!-- 分類名稱, 特色tag, 特點 -->
      <div class="inner_title">
        <!-- 名稱和tag -->
        <h3 class="txt-white txt-bold">
          <span
            v-text="productCategoryName == 0 ? '深紫外線UVC LED' : productCategoryName">
          </span>
          <img src="@/assets/img/icon/icon_exclamation.svg" alt="" style="width: .65em;">&nbsp;
          <div
            v-for="(item, key) in productCategoryTag"
            :key="`tag${key}`"
            class="top_tag txt-white h5 hidden">
              {{item}}
          </div>
          <img
          v-if="productCategory == 2" class="title_logo" src="@/assets/img/logo_2.png" alt="">&nbsp;
          <img
          v-if="productCategory == 3" class="title_logo" src="@/assets/img/logo_3.png" alt="">&nbsp;
        </h3>
        <!-- 特點 -->
        <div class="lightpoint">
          <h6
            v-for="(item, key) in productCategoryPoint"
            :key="`point${key}`"
            class="txt-white">
              <img
                :src="(productCategory == 2) ?
                  require('@/assets/img/products/icon_v_o.svg') :
                  require('@/assets/img/products/icon_v_y.svg')"
                alt="">&nbsp;
                {{item}}
          </h6>
        </div>
      </div>

      <!-- 產品box -->
      <div class="itembox">
        <template v-for="(item, i) in itemsArray">
          <ProductItem
            v-if="productCategoryName === item.category"
            :category="category"
            :item="item"
            :brandOption="brandOption"
            :key="`item_${i}`"/>
        </template>
      </div>

    </div>
  </div>
</template>

<script>
import ProductItem from '@/components/product/ProductItem.vue';

export default {
  name: 'ProductList',
  props: [
    'category',
    'productDiscountTag',
    'productCategory',
    'productCategoryName',
    'productCategoryTag',
    'productCategoryPoint',
    'itemsArray',
    'brandOption',
  ],
  components: {
    ProductItem,
  },
  data() {
    return {
      sortType: '0',
    };
  },
  watch: {
    sortType(newVal, oldVal) {
      if (newVal !== oldVal) {
        const obj = {
          name: 'Product',
          params: { category: this.$route.params.category },
          query: { ...this.$route.query, sort: newVal },
        };
        const code = window.localStorage.getItem('marketCode');
        if (code) {
          obj.query.code = code;
        }
        this.$router.push(obj);
      }
    },
    $route() {
      this.sortType = this.$route.query.sort || 0;
    },
  },
  mounted() {
    this.sortType = this.$route.query.sort || 0;
  },
};
</script>
