import { getStorage } from '../utils/timerStorage';

export default function () {
  return {
    isLogin: getStorage('isLogin') || false,
    user: (getStorage('isLogin') && getStorage('user')) || {},
    isMemberInfo: getStorage('memberInfo') || false,
    isLoading: false,
    lightboxStatus: {
      coopLightbox: false,
      companyLightbox: false,
    },
    // 購物車資訊
    cart: 0,
    totalPriceAndCount: {
      count: 0,
      total: 0,
    },
    takeWay: null,
    order: null,
    orderDetail: null,
    abroad_at: null,
    backhome_at: null,
    acceptRefund: false,
    acceptTerms: false,
    // 購物車資訊
    // 網路商品購買資料
    toolSearch: {
      targetCountry: '',
      unspecifiedTime: false,
      dateRange: '',
      productCategory: '',
      flow: '',
    },
    canCartToDetail: false,
    plusHasWifi: false,
  };
}
