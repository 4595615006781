<template>
  <div class="pages">
    <router-link
      to="/"
      class="page active">
        1
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'Paginate',
  data() {
    return {
    };
  },
};
</script>
