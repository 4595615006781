var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{ref:"convenient",attrs:{"id":"convenient"}},[_c('div',{staticClass:"bg backimg",style:(("background-image: url(" + (require('@/assets/img/index/img_step.jpg')) + ");"))}),_c('div',{staticClass:"bg oblique"}),_c('div',{staticClass:"bg deco",style:(("background-image: url(" + (require('@/assets/img/icon/deco_exclamation_big.svg')) + ");"))}),_c('div',{staticClass:"inner w1300"},[_c('div',{staticClass:"top"},[_c('h2',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal",value:(_vm.$sr_rightSetting),expression:"$sr_rightSetting"}],staticClass:"h2-super_title txt-bold"},[_vm._v(" 超簡單步驟，租借、購買都方便 "),_c('img',{attrs:{"src":require("@/assets/img/icon/icon_exclamation.svg"),"alt":""}})])]),_c('div',{staticClass:"down"},[_c('div',{staticClass:"btnset"},_vm._l((_vm.itemData),function(item,key){return _c('span',{key:key,staticClass:"btn btn-grey",class:{active: _vm.nowChooseItem == key},staticStyle:{"padding":"6px 30px"},on:{"click":function($event){return _vm.stepRun(key)}}},[_c('p',[_vm._v(_vm._s(item.title))])])}),0),_c('div',{staticClass:"showbox active"},[_c('div',{staticClass:"stepbar"},[_vm._l((_vm.itemData[_vm.nowChooseItem].step),function(item,key){return [_c('div',{key:("item" + key),staticClass:"step",class:{
                  active: _vm.nowStep == key,
                  past: _vm.nowStep > key,
                },on:{"click":function($event){return _vm.clickChangeStep(key)}}},[_c('h4',{staticClass:"step_name txt-bold"},[_vm._v(_vm._s(item.stepName))]),_c('div',{staticClass:"number"},[_c('p',[_vm._v(_vm._s(key+1))])])]),(key < (_vm.itemData[_vm.nowChooseItem].step.length - 1))?_c('div',{key:("gap" + key),staticClass:"stepgap"},[_c('div',{staticClass:"stepgap-progress",class:{active: _vm.nowStepGap >= key},style:({
                      animationDuration:
                        _vm.nowStepAnimationRunning
                        ? _vm.stepKeepTime/1000+'s'
                        : '0s'
                    })})]):_vm._e()]})],2),_c('div',{staticClass:"stepinfo"},_vm._l((_vm.itemData[_vm.nowChooseItem].step),function(item,key){return _c('div',{key:key,staticClass:"stepinfo_item",class:{active: _vm.nowStep == key}},[_c('h3',[_vm._v(_vm._s(item.stepTitle))]),_c('h5',{staticClass:"txt-blue"},[_vm._v(_vm._s(item.stepInfo))])])}),0),_c('div',{staticClass:"btnbox txt-center"},[_c('router-link',{staticClass:"btn btn-blue_blue_line",attrs:{"to":_vm.faqLink}},[_c('img',{attrs:{"src":require("@/assets/img/icon/icon_next-gl.svg"),"alt":""}}),_c('p',[_vm._v("查看更多")])])],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }