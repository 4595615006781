<template>
  <div class="tagbox">
    <router-link
      v-for="(item, key) in categoryArray"
      :key="key"
      :to="item.link"
      @click="$emit('passIndex', key)"
      :class="[
        {'router-link-exact-active router-link-active': key === currentIndex},
      ]"
      class="tag_link">
        <img
          src="@/assets/img/icon/icon_plain_3.svg"
          v-if="ifAirport"
          alt=""
          class="color_line">
        <img
          src="@/assets/img/icon/icon_plain_2.svg"
          v-if="ifAirport"
          alt=""
          class="line">
        <img
          src="@/assets/img/icon/icon_plain_1.svg"
          v-if="ifAirport"
          alt=""
          class="color">
        <h6 class="txt-bold">{{item.name}}</h6>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'ArticleTag',
  // categoryArray 會傳這樣的東西進來
  // [
  //    {name: '', link: '/'},
  // ]
  // ifAirport true:false
  props: ['categoryArray', 'ifAirport', 'currentIndex'],
  data() {
    return {
    };
  },
};
</script>
