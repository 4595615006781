<template>
  <div id="News">
    <Header/>
    <div
      id="service_top"
      :style="`background:url(${require('@/assets/img/company_sale_news/banner_news.jpg')});
      background-position: center;
      background-size: cover;
      position: relative;
    }`">
        <div class="inner w1300 txt-white">
          <div class="title txt-center sr_right">
            <p class="txt-white txt-style-i">
              News
              <img src="@/assets/img/icon/icon_exclamation-w.svg" alt="" style="width: .65em;">
            </p>
            <h2 class="h2-super_title txt-bold">
              企業公告 <img src="@/assets/img/icon/icon_exclamation-w.svg" alt="">
            </h2>
          </div>
        </div>
    </div>

    <div id="step">
        <div class=" w1400 breadcrumbs">
          <span>
            <router-link to="/">首頁</router-link>
          </span>
            >
          <router-link to="/CompanyArea">
            企業服務
          </router-link>
            > 企業公告
        </div>
        <div class="inner w1400">
          <div class="bg">
              <div
                class="bg2"
                :style="`background-image: url(${require('@/assets/img/feedback/deco.png')})`">
              </div>
          </div>

          <ArticleTag
            :currentIndex="currentIndex"
            :categoryArray="categoryArray"/>

          <ArticleItemsLine
            :itemsArray="itemsArray"/>

          <Paginate/>
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import ArticleTag from '@/components/article/ArticleTag.vue';
import ArticleItemsLine from '@/components/article/ArticleItemsLine.vue';
import Paginate from '@/components/Paginate.vue';
import { getStorage } from '../utils/timerStorage';

import requestApi from '@/lib/http/index';

import '@/assets/scss/service.scss';

export default {
  name: 'CompanyNews',
  props: ['category', 'page'],
  components: {
    Header,
    ArticleTag,
    ArticleItemsLine,
    Paginate,
  },
  data() {
    return {
      categoryArray: [],
      currentIndex: 0,
      itemsArray: [],
      swiperOption: {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 0,
        allowTouchMove: true,
        autoplay: true,
        delay: 5000,
        speed: 2000,
        pagination: {
          el: '#blog_top .blog_box .swiper-pagination',
          clickable: true,
        },
      },
    };
  },
  methods: {
    async getData() {
      let code = '';
      if (getStorage('marketCode')) {
        code = localStorage.getItem('marketCode');
      }
      const result = await requestApi('companyArticles.index', { code });

      if (result.status) {
        const arr = [];
        result.data.forEach((element) => {
          arr.push({
            linkId: `/CompanyInfo/${element.id}`,
            date: element.publish_day,
            category: element.type,
            title: element.title,
          });
        });
        this.itemsArray = arr;
      } else {
        this.itemsArray = [];
        this.$customSWAL({
          icon: 'error',
          title: '錯誤',
          text: '無法取得資料',
          confirmButtonText: '確定',
        });
      }
    },
    async getDataByCategory(categoryId) {
      let code = '';
      if (getStorage('marketCode')) {
        code = localStorage.getItem('marketCode');
      }
      const type = this.getCategoryName(categoryId);
      const result = await requestApi('companyArticles.index', { type, code });
      this.itemsArray = [];
      if (result.status) {
        const arr = [];
        result.data.forEach((element) => {
          arr.push({
            linkId: `/CompanyInfo/${element.id}`,
            date: element.publish_day,
            category: element.type,
            title: element.title,
          });
        });
        this.itemsArray = arr;
      } else {
        this.itemsArray = [];
        this.$customSWAL({
          icon: 'error',
          title: '錯誤',
          text: '無法取得資料',
          confirmButtonText: '確定',
        });
      }
    },
    async getCategory() {
      const result = await requestApi('companyArticles.getTypes');

      if (result.status) {
        const arr = [];
        arr.push({
          id: null,
          name: '全部公告',
          link: '/CompanyNews?category=&page=1',
        });
        result.data.forEach((element) => {
          arr.push({
            id: element.id,
            name: element.name,
            link: `/CompanyNews?category=${element.id}&page=1`,
          });
        });
        this.categoryArray = arr;
      } else {
        this.categoryArray = [];
        this.$customSWAL({
          icon: 'error',
          title: '錯誤',
          text: '無法取得分類',
          confirmButtonText: '確定',
        });
      }
    },
    getCategoryName(id) {
      let name;
      this.categoryArray.forEach((element) => {
        if (id === element.id) {
          name = element.name;
        }
      });
      return name;
    },
    changeCategoryIndex(category) {
      this.categoryArray.forEach((item, i) => {
        if (item.id === category) {
          this.currentIndex = i;
        }
      });
    },
  },
  watch: {
    $route(to, from) {
      if (to !== from) {
        if (to.query.category) {
          this.getDataByCategory(to.query.category);
          this.changeCategoryIndex(to.query.category);
        } else {
          this.getData();
          this.changeCategoryIndex(null);
        }
      }
    },
  },
  mounted() {
    const getCategory = Promise.resolve(this.getCategory());
    getCategory.then(() => {
      if (this.$route.query.category) {
        this.getDataByCategory(this.$route.query.category);
        this.changeCategoryIndex(this.$route.query.category);
      } else {
        this.getData();
      }
    });
  },
};
</script>
