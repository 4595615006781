export default {
  /**
   * @name 登入
   */
  baseUrl: 'product',
  info: {
    url: '/info',
    token: false,
  },
  infoToken: {
    url: '/info',
    token: true,
  },
  searchResult: {
    url: '/searchResult',
    token: false,
  },
  searchResultToken: {
    url: '/searchResult',
    token: true,
  },
  getQuestionnaires: {
    url: '/getQuestionnaires',
    token: false,
  },
};
